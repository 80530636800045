import { HTTPError } from "ky";
import { ReactElement, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AiOutlineClose } from "react-icons/ai";
import { FaCalendar } from "react-icons/fa";
import { Link, Path, useParams, useSearchParams } from "react-router-dom";
import {
    CancelAndRescheduleChecklistAssignmentEvent,
    EditChecklistAssignmentQuestionScheduleEvent,
} from "../../../core/constants/application-insights-events";
import { ChecklistQuestions, Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    createNavigateToForSearch,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import {
    ContentContainer,
    DetailsLabel,
    DetailsValue,
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { TableRow } from "../../../core/utilities/customTypes";
import {
    applicableHierarchyLevelValuesColumnNames,
    checklistQuestionSetsColumnNames,
    checklistQuestionSetsNestedQuestionsColumnNames,
} from "../../../core/utilities/dataTableColumns";
import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    columnPeriodValue,
    columnStringValue,
} from "../../../core/utilities/questions-column-converter";
import {
    areMutationsLoading,
    areQueriesLoading,
    areQueriesSuccessful,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import EditChecklistAssignmentQuestionScheduleDto from "../../../domain/dtos/checklist-assignment/edit-checklist-assignment-question-schedule-dto";
import EditChecklistAssignmentQuestionSchedulesDto from "../../../domain/dtos/checklist-assignment/edit-checklist-assignment-question-schedules-dto";
import {
    ChecklistAssignmentRoleGroup,
    ChecklistRoleGroup,
    hasRoleTypeInGroup,
} from "../../../domain/enums/Roles";
import {
    useCancelAndRescheduleChecklistAssignment,
    useEditQuestionSchedule,
    useGetChecklistAssignmentDetails,
} from "../../../domain/viewmodels/checklist-assignment/view-checklist-assignment-details-viewmodel";
import SbCheckField from "../../atoms/input/SbCheckField";
import { SbButton } from "../../atoms/SbButton";
import {
    ActivateLink,
    DemoteLink,
    EditLink,
    PromoteLink,
    SbLink,
    SuspendLink,
    ViewLink,
} from "../../atoms/SbLink";
import { SbRibbon } from "../../atoms/SbRibbon";
import { SbAccordion } from "../../molecules/SbAccordion";
import { DataTable } from "../../organisms/DataTable";

export interface ActivateSuspendConfirmationProps {
    checklistId: number;
}

const ViewChecklistAssignmentContainer = (): JSX.Element => {
    const [rows, setRows] = useState<TableRow<number>[]>([]);

    const menu = useMenu();
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const toast = useToast();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const auth = useAuth();
    const navigateSearch = useNavigateSearch();

    const success = urlSearchParams.get("success") === "true" ? true : false;
    const messageKey = urlSearchParams.get("messageKey") ?? "";
    const checklistAssignmentId = Number(useParams().checklistAssignmentId);
    const nodeId = Number(urlSearchParams.get("nodeId"));
    const checklistId = Number(urlSearchParams.get("checklistId"));

    const checklistAssignmentDetails = useGetChecklistAssignmentDetails(
        checklistAssignmentId,
        checklistId,
        nodeId
    );
    const cancelAndRescheduleChecklistAssignment = useCancelAndRescheduleChecklistAssignment();
    const editQuestionSchedule = useEditQuestionSchedule();

    let checklistAssignmentDetailsResponseData = checklistAssignmentDetails[0].data;
    let checklistQuestionSets = checklistAssignmentDetails[3];
    let applicableHierarchyLevelValuesResponseData = checklistAssignmentDetails[4].data;

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Checklists);

        if (success) {
            toast.addToast(createSuccessToastProps([t(messageKey)]));

            urlSearchParams.delete("success");
            urlSearchParams.delete("messageKey");
            setUrlSearchParams(urlSearchParams);
        }
    }, []);

    useLoader(
        areQueriesLoading(checklistAssignmentDetails) ||
            areMutationsLoading([cancelAndRescheduleChecklistAssignment, editQuestionSchedule]),
        ViewChecklistAssignmentContainer
    );

    useEffect(() => {
        if (
            checklistQuestionSets.fetchStatus === "idle" &&
            checklistQuestionSets.status === "success"
        ) {
            setRows(checklistQuestionSets.data!.rows);
        }
    }, [checklistQuestionSets.fetchStatus, checklistQuestionSets.status]);

    const buildLinks = (): ReactElement<typeof Link> => {
        return (
            <>
                {hasRoleTypeInGroup(auth.userRoles, ChecklistRoleGroup.EditAssignmentRoles) &&
                    buildEditLink()}
                {buildViewLink()}
                {buildRemoveChecklistAssignmentLink()}
                {/* {buildActivateSuspendLinks()} */}
                {hasRoleTypeInGroup(auth.userRoles, ChecklistAssignmentRoleGroup.PromoteRoles) &&
                    checklistAssignmentDetailsResponseData!.isEnabled &&
                    buildPromoteDemoteLinks()}
                {hasRoleTypeInGroup(
                    auth.userRoles,
                    ChecklistAssignmentRoleGroup.CancelAndRescheduleRoles
                ) && buildCancelAndRescheduleButton()}
            </>
        );
    };

    const buildEditLink = (): ReactElement<typeof Link> => (
        <EditLink navigateTo={buildEditChecklistAssignmentLinkNavigateTo()} />
    );

    const buildEditChecklistAssignmentLinkNavigateTo = (): Partial<Path> => {
        const params = [
            createNavigateSearchParameter(
                "nodeId",
                checklistAssignmentDetailsResponseData!.nodeId.toString()
            ),
            createNavigateSearchParameter(
                "checklistId",
                checklistAssignmentDetailsResponseData!.checklistId.toString()
            ),
        ];

        return createNavigateToForSearch(
            `${getPath(AccordionTitles.Checklists)}/${checklistAssignmentId}/edit-assignment`,
            params
        );
    };

    const buildViewLink = (): ReactElement<typeof Link> => (
        <ViewLink label="Checklists" navigateTo={`${getPath(AccordionTitles.Checklists)}`} />
    );

    const buildRemoveChecklistAssignmentLink = (): ReactElement<typeof Link> => (
        <SbLink
            label="Remove Checklist Assignment"
            navigateTo={buildRemoveChecklistAssignmentLinkNavigateTo()}
            variant={"danger"}
            icon={AiOutlineClose}
        />
    );

    const buildRemoveChecklistAssignmentLinkNavigateTo = (): Partial<Path> => {
        const params = [
            createNavigateSearchParameter(
                "nodeId",
                checklistAssignmentDetailsResponseData!.nodeId.toString()
            ),
            createNavigateSearchParameter(
                "checklistAssignmentId",
                checklistAssignmentId.toString()
            ),
        ];

        return createNavigateToForSearch(
            `${getPath(AccordionTitles.Checklists)}/${
                checklistAssignmentDetailsResponseData!.checklistId
            }/dissociate-node`,
            params
        );
    };

    // const buildActivateSuspendLinks = (): ReactElement<typeof Link> =>
    //     checklistAssignmentDetailsResponseData!.isEnabled ? (
    //         <SuspendLink navigateTo={buildActivateSuspendChecklistAssignmentNavigateTo()} />
    //     ) : (
    //         <ActivateLink navigateTo={buildActivateSuspendChecklistAssignmentNavigateTo()} />
    //     );

    const buildActivateSuspendChecklistAssignmentNavigateTo = (): Partial<Path> => {
        const params = [
            createNavigateSearchParameter(
                "checklistId",
                checklistAssignmentDetailsResponseData!.checklistId.toString()
            ),
        ];

        return createNavigateToForSearch(
            `${getPath(
                AccordionTitles.Checklists
            )}/${checklistAssignmentId}/activate-suspend-checklist-assignment`,
            params
        );
    };

    const buildPromoteDemoteLinks = (): ReactElement<typeof Link> =>
        checklistAssignmentDetailsResponseData!.isTraining ? (
            <PromoteLink navigateTo={buildPromoteDemoteChecklistAssignmentNavigateTo()} />
        ) : (
            <DemoteLink navigateTo={buildPromoteDemoteChecklistAssignmentNavigateTo()} />
        );

    const buildPromoteDemoteChecklistAssignmentNavigateTo = (): Partial<Path> => {
        const params = [
            createNavigateSearchParameter(
                "checklistId",
                checklistAssignmentDetailsResponseData!.checklistId.toString()
            ),
            createNavigateSearchParameter(
                "nodeId",
                checklistAssignmentDetailsResponseData!.nodeId.toString()
            ),
        ];

        return createNavigateToForSearch(
            `${getPath(
                AccordionTitles.Checklists
            )}/${checklistAssignmentId}/promote-demote-checklist-assignment`,
            params
        );
    };

    const buildCancelAndRescheduleButton = (): ReactElement<typeof Link> => (
        <SbButton
            variant="primary-transparent"
            hoverVariant={"none"}
            type="button"
            label={`${t("CancelAndReschedule")}`}
            icon={FaCalendar}
            onClick={handleCancelAndRescheduleChecklistAssignment}
            alignItemsCenter={false}
            iconSize="xl"
        />
    );

    const handleCancelAndRescheduleChecklistAssignment = (): void => {
        cancelAndRescheduleChecklistAssignment.mutate(checklistAssignmentId, {
            onSuccess: async () => {
                trackAppInsightsEvent(
                    auth.email,
                    window.location.href,
                    CancelAndRescheduleChecklistAssignmentEvent
                );
                toast.addToast(
                    createSuccessToastProps([`${t("CancelAndRescheduleChecklistAssignment")}`])
                );
            },
            onError: (error: HTTPError) => {
                trackAppInsightsException(
                    auth.email,
                    window.location.href,
                    CancelAndRescheduleChecklistAssignmentEvent,
                    error
                );
                errorResponseToDisplayHandler(error);
            },
        });
    };

    const buildSuspensionDateTimeValue = (suspensionDate: Date | null): string | null => {
        if (suspensionDate != null) {
            return toDateAndTimeFormat(suspensionDate.toString());
        }

        return "-";
    };

    const overrideSchedule = (questionSetAssignmentScheduleId: number): void => {
        const questionSetAssignmentSchedule =
            checklistAssignmentDetailsResponseData?.questionSetAssignmentScheduleResponses.filter(
                (x) => x.questionSetAssignmentScheduleId
            );

        const questionSetId = questionSetAssignmentSchedule![0].questionSetId;

        const params = [
            createNavigateSearchParameter(
                "questionSetAssignmentScheduleId",
                questionSetAssignmentScheduleId.toString()
            ),
            createNavigateSearchParameter("questionSetId", questionSetId.toString()),
        ];

        navigateSearch(
            `${getPath(AccordionTitles.Checklists)}/${checklistAssignmentId}/edit-schedule`,
            params
        );
    };

    const overrideQuestionAssignmentSchedules = (questionSetAssignmentScheduleId: number): void => {
        const updatedQuestionSetAssignmentScheduleIndex = rows.findIndex((x) => {
            return x.metadata === questionSetAssignmentScheduleId;
        });

        let editChecklistAssignmentQuestionScheduleDtos: EditChecklistAssignmentQuestionScheduleDto[] =
            [];

        rows[updatedQuestionSetAssignmentScheduleIndex].nestedRows!.map((x) => {
            const questionAssignmentScheduleId = x.metadata!;
            const requiredByTime = columnStringValue("DueByTime", x);
            const openIntervalBeforeDueTime = Number(columnStringValue("OpenBeforeDue", x));
            const openPeriodBeforeDueTime = columnPeriodValue("OpenBeforeDue", x);
            const escalationL1IntervalAfterDueTime = Number(columnStringValue("EL1", x));
            const escalationL1PeriodTypeAfterDueTime = columnPeriodValue("EL1", x);
            const escalationL2IntervalAfterEscalationL1 = Number(columnStringValue("EL2", x));
            const escalationL2PeriodTypeAfterEscalationL1 = columnPeriodValue("EL2", x);
            const escalationL3IntervalAfterEscalationL2 = Number(columnStringValue("EL3", x));
            const escalationL3PeriodTypeAfterEscalationL2 = columnPeriodValue("EL3", x);

            editChecklistAssignmentQuestionScheduleDtos.push(
                new EditChecklistAssignmentQuestionScheduleDto(
                    questionAssignmentScheduleId,
                    requiredByTime!,
                    openIntervalBeforeDueTime,
                    openPeriodBeforeDueTime === undefined ? null : openPeriodBeforeDueTime,
                    escalationL1IntervalAfterDueTime,
                    escalationL1PeriodTypeAfterDueTime === undefined
                        ? null
                        : escalationL1PeriodTypeAfterDueTime,
                    escalationL2IntervalAfterEscalationL1,
                    escalationL2PeriodTypeAfterEscalationL1 === undefined
                        ? null
                        : escalationL2PeriodTypeAfterEscalationL1,
                    escalationL3IntervalAfterEscalationL2,
                    escalationL3PeriodTypeAfterEscalationL2 === undefined
                        ? null
                        : escalationL3PeriodTypeAfterEscalationL2
                )
            );
        });

        editQuestionSchedule.mutate(
            {
                editChecklistAssignmentQuestionSchedulesDto:
                    new EditChecklistAssignmentQuestionSchedulesDto(
                        checklistAssignmentId,
                        questionSetAssignmentScheduleId
                    ),
                editChecklistAssignmentQuestionScheduleDtos:
                    editChecklistAssignmentQuestionScheduleDtos,
            },
            {
                onSuccess: async () => {
                    trackAppInsightsEvent(
                        auth.email,
                        window.location.href,
                        EditChecklistAssignmentQuestionScheduleEvent
                    );
                    toast.addToast(
                        createSuccessToastProps(["Successfully overridden checklist assignment"])
                    );
                    queryClient.refetchQueries(["getChecklistAssignmentDetails"]);
                },
                onError: (error: HTTPError) => {
                    trackAppInsightsException(
                        auth.email,
                        window.location.href,
                        EditChecklistAssignmentQuestionScheduleEvent,
                        error
                    );
                    errorResponseToDisplayHandler(error);
                },
            }
        );
    };

    const cancelQuestionAssignmentSchedules = (_: number): void => {
        setRows(checklistQuestionSets.data!.rows);
    };

    const handleUpdatesToQuestionAssignmentSchedules = (row: TableRow<number>): void => {
        const updatedQuestionSetAssignmentScheduleIndex = rows.findIndex((x) => {
            return x.metadata === row.parentId;
        });

        const updatedQuestionAssignmentScheduleIndex = rows[
            updatedQuestionSetAssignmentScheduleIndex
        ].nestedRows!.findIndex((x) => {
            return x.metadata === row.metadata;
        });

        rows[updatedQuestionSetAssignmentScheduleIndex].nestedRows![
            updatedQuestionAssignmentScheduleIndex
        ] = row;

        const updatedRows = [...rows];

        setRows(updatedRows);
    };

    const renderChecklistDetails = (): JSX.Element => (
        <ContentContainer>
            {checklistAssignmentDetailsResponseData!.isTraining && (
                <SbRibbon size={"large"} label={t("Training", { keyPrefix: Common })} />
            )}
            <Row>
                <Col md="auto">
                    <DetailsLabel>{t("Checklist")}</DetailsLabel>
                    <DetailsLabel>{t("Node")}</DetailsLabel>
                    <DetailsLabel>{t("IsEnabled", { keyPrefix: Common })}</DetailsLabel>
                    <DetailsLabel>{t("AppliesToUsers")}</DetailsLabel>
                    <DetailsLabel>{t("TimeZone", { keyPrefix: Common })}</DetailsLabel>
                    <DetailsLabel>{t("IsTrainingDisplay", { keyPrefix: Common })}</DetailsLabel>
                    <DetailsLabel>{t("AllowActionItemTrigger")}</DetailsLabel>
                    <DetailsLabel>{t("AllowAdhocIntervalOverride")}</DetailsLabel>
                    <DetailsLabel>{t("AllowCustomNotifications")}</DetailsLabel>
                    <DetailsLabel>{t("AllowFileAttachment")}</DetailsLabel>
                    <DetailsLabel>{t("SuspendFrom")}</DetailsLabel>
                    <DetailsLabel>{t("SuspendTo")}</DetailsLabel>
                    <DetailsLabel>{t("Review")}</DetailsLabel>
                </Col>
                <Col md="auto">
                    <DetailsValue>
                        <SbLink
                            variant="primary"
                            label={checklistAssignmentDetails[1].data!.name}
                            navigateTo={`${getPath(AccordionTitles.Checklists)}/${
                                checklistAssignmentDetailsResponseData!.checklistId
                            }`}
                        />
                    </DetailsValue>
                    <DetailsValue>
                        <SbLink
                            variant="primary"
                            label={checklistAssignmentDetails[2].data!.node.nodeTypeValue!.value!}
                            navigateTo={`${getPath(AccordionTitles.VisualTree)}/${
                                checklistAssignmentDetailsResponseData!.nodeId
                            }`}
                        />
                    </DetailsValue>
                    <DetailsValue>
                        <SbCheckField
                            type={"checkbox"}
                            name={"isEnabled"}
                            defaultSelected={checklistAssignmentDetailsResponseData!.isEnabled}
                            disabled
                        />
                    </DetailsValue>
                    <DetailsValue>
                        {checklistAssignmentDetailsResponseData!.appliesToUsers}
                    </DetailsValue>
                    <DetailsValue>
                        {checklistAssignmentDetailsResponseData!.timeZoneId || "-"}
                    </DetailsValue>
                    <DetailsValue>
                        <SbCheckField
                            type={"checkbox"}
                            name={"isTraining"}
                            defaultSelected={checklistAssignmentDetailsResponseData!.isTraining}
                            disabled
                        />
                    </DetailsValue>
                    <DetailsValue>
                        <SbCheckField
                            type={"checkbox"}
                            name={"allowActionItemTrigger"}
                            defaultSelected={
                                checklistAssignmentDetailsResponseData!.allowActionItemTrigger
                            }
                            disabled
                        />
                    </DetailsValue>
                    <DetailsValue>
                        <SbCheckField
                            type={"checkbox"}
                            name={"allowAdhocIntervalOverride"}
                            defaultSelected={
                                checklistAssignmentDetailsResponseData!.allowAdhocIntervalOverride
                            }
                            disabled
                        />
                    </DetailsValue>
                    <DetailsValue>
                        <SbCheckField
                            type={"checkbox"}
                            name={"allowCustomNotifications"}
                            defaultSelected={
                                checklistAssignmentDetailsResponseData!.allowCustomNotifications
                            }
                            disabled
                        />
                    </DetailsValue>
                    <DetailsValue>
                        <SbCheckField
                            type={"checkbox"}
                            name={"allowFileAttachment"}
                            defaultSelected={
                                checklistAssignmentDetailsResponseData!.allowFileAttachment
                            }
                            disabled
                        />
                    </DetailsValue>
                    <DetailsValue>
                        {buildSuspensionDateTimeValue(
                            checklistAssignmentDetailsResponseData!.suspensionDateTimeFrom
                        )}
                    </DetailsValue>
                    <DetailsValue>
                        {buildSuspensionDateTimeValue(
                            checklistAssignmentDetailsResponseData!.suspensionDateTimeTo
                        )}
                    </DetailsValue>
                    <DetailsValue>
                        <SbCheckField
                            type={"checkbox"}
                            name={"review"}
                            defaultSelected={checklistAssignmentDetailsResponseData!.review}
                            disabled
                        />
                    </DetailsValue>
                </Col>
            </Row>

            <EndAlignedDiv>{buildLinks()}</EndAlignedDiv>
        </ContentContainer>
    );

    const renderChecklistQuestionSets = (): JSX.Element => (
        <SbAccordion title={t("ChecklistQuestionSets")}>
            <DataTable
                noResultsMessage={`${t("NoChecklistQuestionSets")}`}
                columns={checklistQuestionSetsColumnNames}
                nestedColumnNames={checklistQuestionSetsNestedQuestionsColumnNames}
                rows={rows}
                handleUpdatesToRows={(row) =>
                    row ? handleUpdatesToQuestionAssignmentSchedules(row) : {}
                }
                overrideItem={overrideSchedule}
                overrideNestedSection={overrideQuestionAssignmentSchedules}
                cancelNestedSection={cancelQuestionAssignmentSchedules}
                showNestedResults
                nestedNoResultsMessage={`${t("NoQuestionsFound", {
                    keyPrefix: ChecklistQuestions,
                })}`}
            />
        </SbAccordion>
    );

    const renderApplicableHierarchyLevelValues = (): JSX.Element => (
        <SbAccordion title={t("ChecklistAssignmentNodeTypeValues")}>
            <DataTable
                noResultsMessage={`${t("NoApplicableHierarchyLevelValues")}`}
                columns={applicableHierarchyLevelValuesColumnNames}
                rows={applicableHierarchyLevelValuesResponseData?.rows}
            />
        </SbAccordion>
    );

    return (
        <>
            {areQueriesSuccessful(checklistAssignmentDetails) && (
                <>
                    <PageHeading>{t("ChecklistAssignmentDetailsTitle")}</PageHeading>
                    <SectionVerticalSpace />

                    {renderChecklistDetails()}
                    <SectionVerticalSpace />

                    {renderChecklistQuestionSets()}
                    <SectionVerticalSpace />

                    {renderApplicableHierarchyLevelValues()}
                    <SectionVerticalSpace />
                </>
            )}
        </>
    );
};

export default ViewChecklistAssignmentContainer;
