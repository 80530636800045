import { ReactElement, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaUnlink } from "react-icons/fa";
import { useParams } from "react-router-dom";
import {
    ActionItemTypes,
    ChecklistQuestions,
    Common,
} from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import {
    useDissociateActionItemSubTypeNode,
    useGetActionItemSubTypeNodeDetails,
} from "../../../domain/viewmodels/questions/dissociate-action-item-sub-type-node-viewmodel";
import { SbButton } from "../../atoms/SbButton";
import { TextConfirmationRow } from "../../organisms/ActionConfirmation";
import { ActionConfirmationFragment } from "../../organisms/ActionConfirmationFragment";

const DissociateQuestionActionItemSubTypeNodeContainer: React.FC = () => {
    const menu = useMenu();
    const navigateSearch = useNavigateSearch();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    const questionId = Number(useParams().questionId);

    const getDissociateActionItemSubTypeNodeDetails =
        useGetActionItemSubTypeNodeDetails(questionId);
    const dissociateActionItemSubTypeNode = useDissociateActionItemSubTypeNode();

    const dissociateActionItemSubTypeNodeDetailsResponseData =
        getDissociateActionItemSubTypeNodeDetails.data;

    useLoader(
        isQueryLoading(getDissociateActionItemSubTypeNodeDetails) ||
            isMutationLoading(dissociateActionItemSubTypeNode),
        DissociateQuestionActionItemSubTypeNodeContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Questions);
    }, []);

    const buildDissociateButton = (): ReactElement<typeof Button> => (
        <SbButton
            variant="danger"
            type="button"
            label={t("Dissociate", { keyPrefix: Common })}
            icon={FaUnlink}
            onClick={() => handleDissociateActionItemSubTypeNode()}
        />
    );

    const handleDissociateActionItemSubTypeNode = (): void => {
        dissociateActionItemSubTypeNode.mutate(questionId, {
            onSuccess: async () => {
                queryClient.invalidateQueries(["getAssociatedActionItemSubTypeNodes"]);

                const params = [
                    createNavigateSearchParameter("success", "true"),
                    createNavigateSearchParameter(
                        "messageKey",
                        "SuccessfullyDissociatedActionItemSubTypeNodeFromQuestion"
                    ),
                ];

                navigateSearch(`${getPath(AccordionTitles.Questions)}/${questionId}`, params);
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    const buildConfirmationRows = (): TextConfirmationRow[] => [
        new TextConfirmationRow(
            t("ActionItemType", { keyPrefix: ActionItemTypes }),
            dissociateActionItemSubTypeNodeDetailsResponseData?.actionItemTypeName
        ),
        new TextConfirmationRow(
            t("ActionItemSubType", { keyPrefix: ActionItemTypes }),
            dissociateActionItemSubTypeNodeDetailsResponseData?.actionItemTypeSubTypeTypeName
        ),
    ];

    return isQuerySuccessful(getDissociateActionItemSubTypeNodeDetails) ? (
        <ActionConfirmationFragment
            pageHeading={t("DissociateQuestionActionItemSubTypeNode")}
            panelTitle={t("DissociateQuestionActionItemSubTypeNodeConfirmationText")}
            rows={buildConfirmationRows()}
            primaryButton={buildDissociateButton()}
        />
    ) : (
        <></>
    );
};

export default DissociateQuestionActionItemSubTypeNodeContainer;
