import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    associatedActionItemSubTypeNode,
    disassociateActionItemSubTypeNode,
} from "../../../data/services/questions/question-service";
import { ActionItemTypeAndSubTypeDto } from "../../dtos/questions/action-item-type-and-sub-type-dto";
import { Response } from "../../responses/common/response-response";

export const useGetActionItemSubTypeNodeDetails = (
    questionId: number
): UseQueryResult<ActionItemTypeAndSubTypeDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getAssociatedActionItemSubTypeNode", questionId],
        () => associatedActionItemSubTypeNode(url.baseUrl, questionId),
        {
            select: ActionItemTypeAndSubTypeDto.constructFromResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useDissociateActionItemSubTypeNode = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    number
> => {
    const url = useUrl();

    return useMutation((mutationKey: number) =>
        disassociateActionItemSubTypeNode(url.baseUrl, mutationKey)
    );
};
