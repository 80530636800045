import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { SingleValue } from "react-select";
import {
    ActionItems,
    ActionItemTypes,
    ChecklistQuestions,
    Common,
} from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
    StyledHiddenOverflowForm,
} from "../../../core/theme/global-styles";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesSuccessful,
    isMutationLoading,
} from "../../../core/utilities/responseStateHelper";
import { ActionItemTypeDto } from "../../../domain/dtos/action-items/action-item-type-dto";
import { AssociateActionItemSubTypeNodeDto } from "../../../domain/dtos/questions/associate-action-item-sub-type-node-dto";
import {
    useAssociateActionItemSubTypeNode,
    useGetActionItemTypesAndSubTypes,
} from "../../../domain/viewmodels/questions/associate-action-item-sub-type-node-viewmodel";
import { AssociateButton, CancelButton } from "../../atoms/SbButton";
import { SbFormSelectFieldGroup } from "../../molecules/input/SbFormSelectFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

const AssociateActionItemSubTypeNodeContainer: React.FC = () => {
    const menu = useMenu();
    const navigate = useNavigate();
    const navigateSearch = useNavigateSearch();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ActionItems });

    const questionId = Number(useParams().questionId);

    const [actionItemTypeNode, setActionItemTypeNode] = useState<ActionItemTypeDto | null>(null);
    const [actionItemSubTypeNode, setActionItemSubTypeNode] = useState<ActionItemTypeDto | null>();

    const associateActionItemSubTypeNode = useAssociateActionItemSubTypeNode();

    const getActionItemTypesAndSubTypes = useGetActionItemTypesAndSubTypes(
        actionItemTypeNode?.nodeId!
    );

    const getActionItemTypes = getActionItemTypesAndSubTypes[0];
    const getActionItemSubTypes = getActionItemTypesAndSubTypes[1];

    const getActionItemTypesResponseData = getActionItemTypes.data;
    const getActionItemSubTypesResponseData = getActionItemSubTypes.data;

    useLoader(
        areQueriesLoading(getActionItemTypesAndSubTypes) ||
            isMutationLoading(associateActionItemSubTypeNode),
        AssociateActionItemSubTypeNodeContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Questions);
    }, []);

    const onChangeActionTypeValue = (option: SingleValue<ActionItemTypeDto>): void => {
        setActionItemTypeNode(option!);
        setActionItemSubTypeNode(null);
    };

    const onChangeActionSubTypeValue = (option: SingleValue<ActionItemTypeDto>): void => {
        setActionItemSubTypeNode(option!);
    };

    const renderActionItemTypes = (): JSX.Element => (
        <TextTitledPanel title={t("ActionItemType", { keyPrefix: ActionItemTypes })}>
            <SbFormSelectFieldGroup
                name={"actionItemType"}
                label={t("ActionItemType")}
                placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                required
                searchable
                clearable={false}
                items={getActionItemTypesResponseData}
                itemDisplayText={(option: ActionItemTypeDto) => option.value}
                onChange={onChangeActionTypeValue}
            />

            <SbFormSelectFieldGroup
                name={"actionItemSubType"}
                label={t("ActionItemSubType", { keyPrefix: ActionItemTypes })}
                placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                required
                searchable
                clearable={false}
                items={getActionItemSubTypesResponseData}
                itemDisplayText={(option: ActionItemTypeDto) => option.value}
                onChange={onChangeActionSubTypeValue}
                value={actionItemSubTypeNode}
            />
        </TextTitledPanel>
    );

    const renderActionButtons = (): JSX.Element => (
        <EndAlignedDiv>
            <AssociateButton onClick={handleSubmit} />
            <CancelButton
                onClick={() => navigate(`${getPath(AccordionTitles.Questions)}/${questionId}`)}
            />
        </EndAlignedDiv>
    );

    const handleSubmit = (): void => {
        associateActionItemSubTypeNode.mutate(
            new AssociateActionItemSubTypeNodeDto(questionId, actionItemSubTypeNode!.nodeId),
            {
                onSuccess: async () => {
                    const params = [
                        createNavigateSearchParameter("success", "true"),
                        createNavigateSearchParameter(
                            "messageKey",
                            "SuccessfullyAssociatedActionItemSubTypeNode"
                        ),
                    ];

                    navigateSearch(`${getPath(AccordionTitles.Questions)}/${questionId}`, params);
                },
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    return (
        <>
            <PageHeading>
                {t("ActionItemTypeNodeAssociateHeading", { keyPrefix: ChecklistQuestions })}
            </PageHeading>
            <SectionVerticalSpace />

            <StyledHiddenOverflowForm onSubmit={handleSubmit}>
                {areQueriesSuccessful([getActionItemTypes]) && (
                    <>
                        {renderActionItemTypes()}
                        <SectionVerticalSpace />
                        {renderActionButtons()}
                    </>
                )}
            </StyledHiddenOverflowForm>
        </>
    );
};

export default AssociateActionItemSubTypeNodeContainer;
