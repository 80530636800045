import { useMutation, UseMutationResult, useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    getActionItemSubTypesByActionItemTypeNodeId,
    getActionItemTypes,
} from "../../../data/services/action-items/action-item-types-service";
import { getActionItemImportance } from "../../../data/services/actions/action-item-importance-service";
import {
    editActionItem,
    getActionItemDetails,
    getActionItemInstanceFiles,
    getAdditionalFields,
    getAdditionalFieldValues,
} from "../../../data/services/actions/action-items-service";
import {
    ActionItemTypeDto,
    toActionItemTypeDtos,
} from "../../dtos/action-items/action-item-type-dto";
import { ActionItemDetailsDto } from "../../dtos/actions/action-item-details-dto";
import { ActionItemDynamicFieldValueDto } from "../../dtos/actions/action-item-dynamic-field-values-dto";
import {
    ActionItemDynamicFieldDetailsDto,
    toActionItemDynamicFieldDetailsDtos,
} from "../../dtos/actions/action-item-dynamic-fields-dto";
import {
    ActionItemImportanceDto,
    toActionItemImportanceDtos,
} from "../../dtos/actions/action-item-importance-dto";
import { EditActionItemDto } from "../../dtos/actions/edit-action-item-dto";
import { FileDto } from "../../dtos/file-storage/file-dto";
import { EditActionItemRequest } from "../../requests/actions/edit-action-item-request";
import { Response } from "../../responses/common/response-response";

export const useGetActionItemDetails = (
    actionItemId: number,
    actionItemTypeNodeId: number | null
): [
    UseQueryResult<ActionItemTypeDto[], HTTPError>,
    UseQueryResult<ActionItemTypeDto[], HTTPError>,
    UseQueryResult<ActionItemImportanceDto[], HTTPError>,
    UseQueryResult<ActionItemDetailsDto, HTTPError>,
    UseQueryResult<ActionItemDynamicFieldValueDto[], HTTPError>,
    UseQueryResult<ActionItemDynamicFieldDetailsDto[], HTTPError>,
    UseQueryResult<FileDto[], HTTPError>,
] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["getActionItemTypes"],
                queryFn: () => getActionItemTypes(url.baseUrl),
                select: toActionItemTypeDtos,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getActionItemSubTypesByActionItemId", actionItemTypeNodeId],
                enabled: actionItemTypeNodeId != null,
                queryFn: () =>
                    getActionItemSubTypesByActionItemTypeNodeId(url.baseUrl, actionItemTypeNodeId!),
                select: toActionItemTypeDtos,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getActionItemImportance"],
                queryFn: () => getActionItemImportance(url.baseUrl),
                select: toActionItemImportanceDtos,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getActionItemDetails", actionItemId],
                queryFn: () => getActionItemDetails(url.baseUrl, actionItemId!),
                select: ActionItemDetailsDto.constructFromResponse,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getAdditionalFieldValues", actionItemId],
                queryFn: () => getAdditionalFieldValues(url.baseUrl, actionItemId!),
                select: ActionItemDynamicFieldValueDto.toActionItemDynamicFieldValueDtos,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getAdditionalFields", actionItemTypeNodeId],
                enabled: actionItemTypeNodeId != null,
                queryFn: () => getAdditionalFields(url.baseUrl, actionItemTypeNodeId!),
                select: toActionItemDynamicFieldDetailsDtos,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getActionItemInstanceFiles", actionItemId],
                queryFn: () => getActionItemInstanceFiles(url.baseUrl, actionItemId),
                select: FileDto.constructFromActionItemInstanceFileDownloadResponses,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<ActionItemTypeDto[], HTTPError>,
        UseQueryResult<ActionItemTypeDto[], HTTPError>,
        UseQueryResult<ActionItemImportanceDto[], HTTPError>,
        UseQueryResult<ActionItemDetailsDto, HTTPError>,
        UseQueryResult<ActionItemDynamicFieldValueDto[], HTTPError>,
        UseQueryResult<ActionItemDynamicFieldDetailsDto[], HTTPError>,
        UseQueryResult<FileDto[], HTTPError>,
    ];
};

export const useEditActionItem = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    EditActionItemDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: EditActionItemDto) => {
        const request = new EditActionItemRequest(mutationKey);

        return editActionItem(url.baseUrl, request);
    });
};
