import { ReactElement, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SingleValue } from "react-select";
import {
    ActionItems,
    ActionItemTypes,
    Common,
    Nodes,
} from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    ContentContainer,
    DetailsLabel,
    DetailsValue,
    EndAlignedDiv,
    LargeVerticalSpace,
    maxContentWidthSelectStyle,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
    StyledHiddenOverflowForm,
} from "../../../core/theme/global-styles";
import { toDateAndTimeFormatLocal } from "../../../core/utilities/date-helper";
import { getEnumsForType } from "../../../core/utilities/enum-helper";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { actionItemDynamicFieldValueTypeBooleanDropdownItems } from "../../../core/utilities/filterDropdownItems";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    isMutationLoading,
    isQueryLoading,
} from "../../../core/utilities/responseStateHelper";
import { ActionItemTypeDto } from "../../../domain/dtos/action-items/action-item-type-dto";
import { ActionItemImportanceDto } from "../../../domain/dtos/actions/action-item-importance-dto";
import { CreateActionItemDto } from "../../../domain/dtos/actions/create-action-item-dto";
import { RaciParticipantDto } from "../../../domain/dtos/actions/raci-participant-dto";
import { createBasePaginationDto } from "../../../domain/dtos/common/base-pagination-dto";
import {
    createReactSelectDto,
    ReactSelectDto,
    valuesToReactSelectDtos,
} from "../../../domain/dtos/common/react-select-dto";
import { createBaseUserDto } from "../../../domain/dtos/users/base-user-dto";
import { ActionItemImportance } from "../../../domain/enums/action-items/ActionItemImportance";
import { ActionItemNodeAssociationMode } from "../../../domain/enums/action-items/ActionItemNodeAssociationMode";
import { NotificationTriggersPeriodType } from "../../../domain/enums/action-items/NotificationTriggersPeriodType";
import RaciRelationship from "../../../domain/enums/action-items/RaciRelationship";
import AnswerValueType from "../../../domain/enums/answer-types/answer-value-type";
import { ActionItemResponse } from "../../../domain/responses/actions/action-item-response";
import { Response } from "../../../domain/responses/common/response-response";
import {
    useCreateActionItem,
    useGetActionItemFields,
} from "../../../domain/viewmodels/actions/create-action-item-viewmodel";
import SbNumberBox from "../../atoms/input/SbNumberBox";
import { SbSelect } from "../../atoms/input/SbSelect";
import { SbAlert } from "../../atoms/SbAlert";
import { AssociateButton, CancelButton, SbButton } from "../../atoms/SbButton";
import { translateText } from "../../helpers/translate";
import { translateWithFallback } from "../../helpers/translate-with-common-fallback";
import SbFormCheckFieldGroup from "../../molecules/input/SbFormCheckFieldGroup";
import SbFormDateTimeFieldGroup from "../../molecules/input/SbFormDateTimeFieldGroup";
import SbFormNumberBoxGroup from "../../molecules/input/SbFormNumberBoxGroup";
import { SbFormSelectFieldGroup } from "../../molecules/input/SbFormSelectFieldGroup";
import SbFormTextAreaGroup from "../../molecules/input/SbFormTextAreaGroup";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import SbModal, { ModalSize } from "../../molecules/SbModal";
import { TextTitledPanel } from "../../molecules/SbPanel";
import { RaciParticipant } from "../../organisms/RaciParticipant";
import { AssignToHierarchyComponent } from "../action-items/AssignToHierarchyComponent";
import {
    createErrorToastProps,
    useToast,
} from "../../../core/store/toast-context";

const CreateActionItemContainer: React.FC = () => {
    const menu = useMenu();
    const auth = useAuth();
    const navigate = useNavigate();
    const toast = useToast();
    const navigateSearch = useNavigateSearch();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const [urlSearchParams] = useSearchParams();

    const actionPlanId =
        urlSearchParams.get("actionPlanId") != undefined
            ? Number(urlSearchParams.get("actionPlanId"))
            : undefined;

    const startDateSearchParam = urlSearchParams.get("startDate");
    const dueDateSearchParam = urlSearchParams.get("dueDate");

    const [dateState, setDateState] = useState({
        startDate: startDateSearchParam ? new Date(startDateSearchParam) : new Date(),
        dueDate: dueDateSearchParam ? new Date(dueDateSearchParam) : null
    });
    const [userSearchText, setUserSearchText] = useState<string | null>(null);
    const [raciParticipants, setRaciParticipants] = useState<RaciParticipantDto[]>([]);
    const [selectedActionItemSubType, setSelectedActionItemSubType] =
        useState<ActionItemTypeDto | null>(null);
    const [actionItemTypeNodeId, setActionItemTypeNodeId] = useState<number | null>(null);
    const [actionItemSubTypeNodeId, setActionItemSubTypeNodeId] = useState<number | null>(null);
    const [actionItemImportance, setActionItemImportance] = useState<number>(
        ActionItemImportance.Medium
    );
    const [overrideNotificationTriggers, setOverrideNotificationTriggers] = useState(false);
    const [showAssociateNodeModal, setShowAssociateNodeModal] = useState(false);
    const [createActionItemDto, setCreateActionItemDto] = useState<CreateActionItemDto>();
    const { t } = useTranslation("translation", { keyPrefix: ActionItems });
    const [selectedNode, setSelectedNode] = useState<number | undefined>(undefined);

    const createActionItem = useCreateActionItem();

    const getActionItemFields = useGetActionItemFields(
        actionItemTypeNodeId,
        createBasePaginationDto(1, 1000),
        userSearchText
    );
    const getActionItemTypes = getActionItemFields[0];
    const getActionItemSubTypes = getActionItemFields[1];
    const getActionItemImportances = getActionItemFields[2];
    const getAdditionalFields = getActionItemFields[3];
    const getFilterUsers = getActionItemFields[4];

    const getActionItemTypesResponseData = getActionItemFields[0].data;
    const getActionItemSubTypesResponseData = getActionItemFields[1].data;
    const getActionItemImportancesResponseData = getActionItemFields[2].data;
    const getAdditionalFieldsResponseData = getActionItemFields[3].data;
    const getFilterUsersResponseData = getActionItemFields[4].data;

    const authUser = createBaseUserDto(Number(auth.userId), "", "", auth.email, auth.userName, "");

    useLoader(
        isMutationLoading(createActionItem) ||
            areQueriesLoading([
                getActionItemTypes,
                getActionItemSubTypes,
                getActionItemImportances,
                getAdditionalFields,
            ]),
        CreateActionItemContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.ActionTracker);
        menu.changeActiveDrawerItem(DrawerTitles.ActionItems);
    }, []);

    const getIsStartDateBeforeDueDate  = (startDate: Date, dueDate: Date): boolean =>{
        if (startDate >= dueDate){
            return false;
        }
        return true;
    }

    const handleShowAssociateNodeModal = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = new FormData(event.currentTarget);
        const startDate = new Date(formData.get("startDate") as string);
        const dueDate = new Date(formData.get("dueDate") as string);
        const isStartDateBeforeDueDate  = getIsStartDateBeforeDueDate (startDate, dueDate);
        const currentDate = new Date()
        dueDate.setSeconds(0, 0);
        currentDate.setSeconds(0, 0);
        const isDueDateInFuture = dueDate > currentDate
        
        if (isStartDateBeforeDueDate  && isDueDateInFuture ){
            setShowAssociateNodeModal(true);
            setCreateActionItemDto(
                CreateActionItemDto.createActionItemDto(
                    formData,
                    actionItemImportance,
                    actionItemSubTypeNodeId!,
                    getAdditionalFieldsResponseData!,
                    raciParticipants!,
                    actionPlanId,
                    selectedNode!,
                    ActionItemNodeAssociationMode.Organisational
                )
            );
        }

        if (!isStartDateBeforeDueDate ) {
            toast.addToast(createErrorToastProps([t("ActionItemInstanceStartDateBeforeDueDate")]));
        }

        if (!isDueDateInFuture ) {
            toast.addToast(createErrorToastProps([t("ActionItemInstanceDueDateTimeBeforeCurrentTime")]));
        }

    };

    const handleSaveActionItem = (): void => {
        const actionItem: CreateActionItemDto = {
            ...createActionItemDto!,
            organisationalNodeId: selectedNode!,
        };

        setCreateActionItemDto(actionItem);

        createActionItem.mutate(actionItem!, {
            onSuccess: async (response: Response<ActionItemResponse>) => {
                const actionItemId = response.data.actionItemId;
                const params = [
                    createNavigateSearchParameter("success", "true"),
                    createNavigateSearchParameter(
                        "messageKey",
                        "SuccessfullyCreatedActionItemAndAssociatedOrganisationalNode"
                    ),
                ];
                navigateSearch(`${getPath(DrawerTitles.ActionItems)}/${actionItemId}`, params);
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    const onActionItemImportanceSelected = (option: SingleValue<ActionItemImportanceDto>): void =>
        setActionItemImportance(option!.actionItemImportanceId);

    const onChangeActionTypeValue = (option: SingleValue<ActionItemTypeDto>): void => {
        setActionItemTypeNodeId(option!.nodeId);
        setActionItemSubTypeNodeId(null);
        setSelectedActionItemSubType(null);
    };

    const onChangeActionSubTypeValue = (option: SingleValue<ActionItemTypeDto>): void => {
        setActionItemSubTypeNodeId(option!.nodeId);
        if (getActionItemSubTypesResponseData) {
            const selectedSubType = getActionItemSubTypesResponseData.find(
                (st) => st.nodeId === option!.nodeId
            );
            setSelectedActionItemSubType(selectedSubType!);
        } else setSelectedActionItemSubType(null);
    };

    const onAddRaciParticipant = (): void => {
        const allRaciParticipantsArr = [...raciParticipants];

        const maxIndex = Math.max(...allRaciParticipantsArr.map((x) => x.index ?? 1));
        allRaciParticipantsArr.push(
            new RaciParticipantDto(
                allRaciParticipantsArr.length >= 1 ? maxIndex + 1 : 1,
                RaciRelationship.Responsible,
                null,
                false
            )
        );

        allRaciParticipantsArr.sort((a, b) => (a.index > b.index ? 1 : -1));

        setRaciParticipants(allRaciParticipantsArr);
    };

    const onRemoveRaciParticipant = (index: number): void => {
        const allRaciParticipantsArr = [...raciParticipants];

        const filteredRaciParticipantsArr = allRaciParticipantsArr.filter((x) => x.index != index);

        filteredRaciParticipantsArr.sort((a, b) => (a.index > b.index ? 1 : -1));

        setRaciParticipants(filteredRaciParticipantsArr);
    };

    const onUpdateRaciParticipant = (index: number, raciRelationship: RaciRelationship): void => {
        const allRaciParticipantsArr = [...raciParticipants];

        const filteredRaciParticipantsArr = allRaciParticipantsArr.filter((x) => x.index != index);

        filteredRaciParticipantsArr.push(
            new RaciParticipantDto(index, raciRelationship, null, false)
        );

        filteredRaciParticipantsArr.sort((a, b) => (a.index > b.index ? 1 : -1));

        setRaciParticipants(filteredRaciParticipantsArr);
    };

    const onOverrideNotificationTriggers = (_: string, checked: boolean): void => {
        setOverrideNotificationTriggers(checked);
    };

    const handleDateChange = (name: string, value: any) => {
        setDateState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
    };

    const renderActionItemDetail = (): JSX.Element => (
        <TextTitledPanel title={t("ActionItemDetails")}>
            <SbFormTextFieldGroup
                name="name"
                maxLength={255}
                label={t("Name", { keyPrefix: Common })}
                type="text"
                required
            />

            <SbFormTextAreaGroup
                name="description"
                label={t("Description", { keyPrefix: Common })}
                maxLength={4000}
                required
                rows={4}
            />

            <SbFormSelectFieldGroup
                name={"actionItemImportance"}
                label={t("ActionItemUrgency")}
                placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                required
                searchable={false}
                clearable={false}
                items={getActionItemImportancesResponseData}
                itemDisplayText={(option: ActionItemImportanceDto) =>
                    t(ActionItemImportance[option.actionItemImportanceId], { keyPrefix: Common })
                }
                onChange={onActionItemImportanceSelected}
                defaultSelectedItem={getActionItemImportancesResponseData?.find(
                    (x) => x.actionItemImportanceId === ActionItemImportance.Medium
                )}
            />

            <SbFormDateTimeFieldGroup
                name="startDate"
                label={t("StartDate")}
                defaultValue={dateState.startDate}
                onInputChange={(value) => handleDateChange("startDate", value)}
                required
            />

            <SbFormDateTimeFieldGroup
                name="dueDate"
                label={t("DueDate")}
                defaultValue={dateState.dueDate}
                onInputChange={(value) => handleDateChange("dueDate", value)}
                required
            />

            <SbFormCheckFieldGroup
                fieldLabel={t("ExcludeFromReporting")}
                type="checkbox"
                values={[
                    {
                        name: "isPersonalActionItem",
                    },
                ]}
                toolTip={t("ExcludeFromReportingHelpContent")}
            />
        </TextTitledPanel>
    );

    const renderActionItemTypes = (): JSX.Element => (
        <TextTitledPanel title={t("ActionItemType", { keyPrefix: ActionItemTypes })}>
            <SbFormSelectFieldGroup
                name={"actionItemType"}
                label={t("ActionItemType")}
                placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                required
                searchable
                clearable={false}
                items={getActionItemTypesResponseData}
                itemDisplayText={(option: ActionItemTypeDto) => option.value} //TODO: Add translations for dynamic data
                onChange={onChangeActionTypeValue}
            />

            {actionItemTypeNodeId && (
                <SbFormSelectFieldGroup
                    name={"actionItemSubType"}
                    label={t("ActionItemSubType", { keyPrefix: ActionItemTypes })}
                    placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                    required
                    searchable
                    clearable={false}
                    value={selectedActionItemSubType}
                    items={getActionItemSubTypesResponseData}
                    itemDisplayText={(option: ActionItemTypeDto) => option.value} //TODO: Add translations for dynamic data
                    onChange={onChangeActionSubTypeValue}
                />
            )}
        </TextTitledPanel>
    );

    const renderRaciParticipants = (): JSX.Element => (
        <TextTitledPanel title={t("RaciParticipants", { keyPrefix: Common })}>
            {getActionItemFields && (
                <RaciParticipant
                    useDefaults
                    raciParticipants={raciParticipants}
                    userList={getFilterUsersResponseData!}
                    isLoading={isQueryLoading(getFilterUsers)}
                    onAddRaciParticipant={onAddRaciParticipant}
                    onRemoveRaciParticipant={onRemoveRaciParticipant}
                    onUpdateRaciParticipant={onUpdateRaciParticipant}
                    setUserSearchText={setUserSearchText}
                    authUserDto={authUser}
                />
            )}
        </TextTitledPanel>
    );

    const renderAdditionalInformation = (): JSX.Element => (
        <>
            {getAdditionalFields && (
                <>
                    <TextTitledPanel title={t("AdditionalInformation")}>
                        {getAdditionalFieldsResponseData?.map((x) => {
                            switch (x.valueType) {
                                case AnswerValueType.Text:
                                    return (
                                        <SbFormTextFieldGroup
                                            name={x.actionItemDynamicFieldId.toString()}
                                            label={x.fieldName}
                                            required={x.isRequired}
                                            maxLength={1000}
                                            type={"text"}
                                        />
                                    );

                                case AnswerValueType.TextArea:
                                    return (
                                        <SbFormTextAreaGroup
                                            name={x.actionItemDynamicFieldId.toString()}
                                            label={x.fieldName}
                                            required={x.isRequired}
                                            maxLength={4000}
                                            rows={4}
                                        />
                                    );

                                case AnswerValueType.Boolean:
                                    return (
                                        <SbFormSelectFieldGroup
                                            name={x.actionItemDynamicFieldId.toString()}
                                            label={x.fieldName}
                                            placeholderText={t("NotSet", { keyPrefix: Nodes })}
                                            required={x.isRequired}
                                            searchable={false}
                                            clearable={false}
                                            items={valuesToReactSelectDtos(
                                                actionItemDynamicFieldValueTypeBooleanDropdownItems
                                            )}
                                            itemDisplayText={(option: ReactSelectDto<string>) =>
                                                translateWithFallback(t, option.value, Nodes)
                                            }
                                        />
                                    );

                                case AnswerValueType.DateTime:
                                    return (
                                        <SbFormDateTimeFieldGroup
                                            name={x.actionItemDynamicFieldId.toString()}
                                            label={x.fieldName}
                                            required={x.isRequired}
                                        />
                                    );

                                case AnswerValueType.List:
                                    return (
                                        <SbFormSelectFieldGroup
                                            name={x.actionItemDynamicFieldId.toString()}
                                            label={x.fieldName}
                                            placeholderText={
                                                t("PleaseSelect", { keyPrefix: Common })!
                                            }
                                            required={x.isRequired}
                                            searchable
                                            clearable
                                            items={valuesToReactSelectDtos(x.listData.split("|"))}
                                            itemDisplayText={
                                                (option: ReactSelectDto<string>) => option.value //TODO: Add translations for dynamic data
                                            }
                                        />
                                    );

                                case AnswerValueType.Integer:
                                case AnswerValueType.Decimal:
                                    return (
                                        <SbFormNumberBoxGroup
                                            name={x.actionItemDynamicFieldId.toString()}
                                            required={x.isRequired}
                                            label={x.fieldName}
                                        />
                                    );
                            }
                        })}
                    </TextTitledPanel>
                    <SectionVerticalSpace />
                </>
            )}
        </>
    );

    const renderOptionalInformation = (): JSX.Element => (
        <TextTitledPanel title={t("OptionalInformation")}>
            <SbFormTextAreaGroup
                name={"requiredOutcome"}
                label={t("RemedialAction")}
                maxLength={4000}
                rows={4}
            />
        </TextTitledPanel>
    );

    const renderNotificationTriggers = (): JSX.Element => (
        <TextTitledPanel title={t("NotificationTriggers")}>
            <SbFormCheckFieldGroup
                fieldLabel={t("OverrideDefaultNotificationTriggers")}
                type="checkbox"
                values={[
                    {
                        name: "overrideNotificationTriggers",
                        onChangeHandler: onOverrideNotificationTriggers,
                    },
                ]}
            />

            {overrideNotificationTriggers && (
                <>
                    <Row>
                        <Col md={1} />
                        <Col md="auto">{t("Notify", { keyPrefix: Common })}</Col>
                        <Col md={1}>
                            <SbNumberBox
                                name={"firstPreDueDateNotificationValue"}
                                defaultValue={0}
                            />
                        </Col>
                        <Col md={1}>
                            <SbSelect
                                name={"firstNotificationPeriodType"}
                                searchable={false}
                                styles={maxContentWidthSelectStyle}
                                clearable={false}
                                defaultSelectedItem={createReactSelectDto(
                                    NotificationTriggersPeriodType.Hours,
                                    NotificationTriggersPeriodType[
                                        NotificationTriggersPeriodType.Hours
                                    ]
                                )}
                                items={getEnumsForType(NotificationTriggersPeriodType).map((x) =>
                                    createReactSelectDto(x, NotificationTriggersPeriodType[x])
                                )}
                                itemLabel={(
                                    option: ReactSelectDto<NotificationTriggersPeriodType>
                                ) => translateText(t, option.label, Common)}
                                itemValue={(
                                    option: ReactSelectDto<NotificationTriggersPeriodType>
                                ) => option.value.toString()}
                            />
                        </Col>
                        <Col md="auto">{t("BeforeDueDate", { keyPrefix: Common })}</Col>
                    </Row>
                    <Row>
                        <Col md={1} />
                        <Col md="auto">{t("Notify", { keyPrefix: Common })}</Col>
                        <Col md={1}>
                            <SbNumberBox
                                name={"secondPreDueDateNotificationValue"}
                                defaultValue={0}
                            />
                        </Col>
                        <Col md={1}>
                            <SbSelect
                                name={"secondNotificationPeriodType"}
                                searchable={false}
                                styles={maxContentWidthSelectStyle}
                                clearable={false}
                                defaultSelectedItem={createReactSelectDto(
                                    NotificationTriggersPeriodType.Hours,
                                    NotificationTriggersPeriodType[
                                        NotificationTriggersPeriodType.Hours
                                    ]
                                )}
                                items={getEnumsForType(NotificationTriggersPeriodType).map((x) =>
                                    createReactSelectDto(x, NotificationTriggersPeriodType[x])
                                )}
                                itemLabel={(
                                    option: ReactSelectDto<NotificationTriggersPeriodType>
                                ) => translateText(t, option.label, Common)}
                                itemValue={(
                                    option: ReactSelectDto<NotificationTriggersPeriodType>
                                ) => option.value.toString()}
                            />
                        </Col>
                        <Col md="auto">{t("BeforeDueDate", { keyPrefix: Common })}</Col>
                    </Row>
                    <Row>
                        <Col md={1} />
                        <Col md="auto">{t("Notify", { keyPrefix: Common })}</Col>
                        <Col md={1}>
                            <SbNumberBox
                                name={"thirdPreDueDateNotificationValue"}
                                defaultValue={0}
                            />
                        </Col>
                        <Col md={1}>
                            <SbSelect
                                name={"thirdNotificationPeriodType"}
                                searchable={false}
                                clearable={false}
                                styles={maxContentWidthSelectStyle}
                                defaultSelectedItem={createReactSelectDto(
                                    NotificationTriggersPeriodType.Hours,
                                    NotificationTriggersPeriodType[
                                        NotificationTriggersPeriodType.Hours
                                    ]
                                )}
                                items={getEnumsForType(NotificationTriggersPeriodType).map((x) =>
                                    createReactSelectDto(x, NotificationTriggersPeriodType[x])
                                )}
                                itemLabel={(
                                    option: ReactSelectDto<NotificationTriggersPeriodType>
                                ) => translateText(t, option.label, Common)}
                                itemValue={(
                                    option: ReactSelectDto<NotificationTriggersPeriodType>
                                ) => option.value.toString()}
                            />
                        </Col>
                        <Col md="auto">{t("BeforeDueDate", { keyPrefix: Common })}</Col>
                    </Row>
                    <Row>
                        <Col md={1} />
                        <Col md="auto">{t("Notify", { keyPrefix: Common })}</Col>
                        <Col md={1}>
                            <SbNumberBox
                                name={"firstPostDueDateNotificationValue"}
                                defaultValue={0}
                            />
                        </Col>
                        <Col md={1}>
                            <SbSelect
                                name={"fourthNotificationPeriodType"}
                                searchable={false}
                                styles={maxContentWidthSelectStyle}
                                clearable={false}
                                defaultSelectedItem={createReactSelectDto(
                                    NotificationTriggersPeriodType.Hours,
                                    NotificationTriggersPeriodType[
                                        NotificationTriggersPeriodType.Hours
                                    ]
                                )}
                                items={getEnumsForType(NotificationTriggersPeriodType).map((x) =>
                                    createReactSelectDto(x, NotificationTriggersPeriodType[x])
                                )}
                                itemLabel={(
                                    option: ReactSelectDto<NotificationTriggersPeriodType>
                                ) => translateText(t, option.label, Common)}
                                itemValue={(
                                    option: ReactSelectDto<NotificationTriggersPeriodType>
                                ) => option.value.toString()}
                            />
                        </Col>
                        <Col md="auto">{t("AfterDueDate", { keyPrefix: Common })}</Col>
                    </Row>
                    <Row>
                        <Col md={1} />
                        <Col md="auto">{t("Notify", { keyPrefix: Common })}</Col>
                        <Col md={1}>
                            <SbNumberBox
                                name={"secondPostDueDateNotificationValue"}
                                defaultValue={0}
                            />
                        </Col>
                        <Col md={1}>
                            <SbSelect
                                name={"fifthNotificationPeriodType"}
                                searchable={false}
                                styles={maxContentWidthSelectStyle}
                                clearable={false}
                                defaultSelectedItem={createReactSelectDto(
                                    NotificationTriggersPeriodType.Hours,
                                    NotificationTriggersPeriodType[
                                        NotificationTriggersPeriodType.Hours
                                    ]
                                )}
                                items={getEnumsForType(NotificationTriggersPeriodType).map((x) =>
                                    createReactSelectDto(x, NotificationTriggersPeriodType[x])
                                )}
                                itemLabel={(
                                    option: ReactSelectDto<NotificationTriggersPeriodType>
                                ) => translateText(t, option.label, Common)}
                                itemValue={(
                                    option: ReactSelectDto<NotificationTriggersPeriodType>
                                ) => option.value.toString()}
                            />
                        </Col>
                        <Col md="auto">{t("AfterDueDate", { keyPrefix: Common })}</Col>
                    </Row>
                    <Row>
                        <Col md={1} />
                        <Col md="auto">{t("Notify", { keyPrefix: Common })}</Col>
                        <Col md={1}>
                            <SbNumberBox
                                name={"thirdPostDueDateNotificationValue"}
                                defaultValue={0}
                            />
                        </Col>
                        <Col md={1}>
                            <SbSelect
                                name={"sixthNotificationPeriodType"}
                                searchable={false}
                                styles={maxContentWidthSelectStyle}
                                clearable={false}
                                defaultSelectedItem={createReactSelectDto(
                                    NotificationTriggersPeriodType.Hours,
                                    NotificationTriggersPeriodType[
                                        NotificationTriggersPeriodType.Hours
                                    ]
                                )}
                                items={getEnumsForType(NotificationTriggersPeriodType).map((x) =>
                                    createReactSelectDto(x, NotificationTriggersPeriodType[x])
                                )}
                                itemLabel={(
                                    option: ReactSelectDto<NotificationTriggersPeriodType>
                                ) => translateText(t, option.label, Common)}
                                itemValue={(
                                    option: ReactSelectDto<NotificationTriggersPeriodType>
                                ) => option.value.toString()}
                            />
                        </Col>
                        <Col md="auto">{t("AfterDueDate", { keyPrefix: Common })}</Col>
                    </Row>
                </>
            )}
        </TextTitledPanel>
    );

    const renderActionItemDetails = (): JSX.Element => (
        <ContentContainer>
            <Row>
                <Col md="auto">
                    <DetailsLabel>{t("ActionItem", { keyPrefix: ActionItems })}</DetailsLabel>
                    <DetailsLabel>{t("Description", { keyPrefix: Common })}</DetailsLabel>
                    <DetailsLabel>{t("StartDate", { keyPrefix: ActionItems })}</DetailsLabel>
                    <DetailsLabel>{t("DueDate", { keyPrefix: ActionItems })}</DetailsLabel>
                </Col>
                <Col md="auto">
                    <DetailsValue>{createActionItemDto?.name}</DetailsValue>
                    <DetailsValue>{createActionItemDto?.description}</DetailsValue>
                    <DetailsValue>
                        {toDateAndTimeFormatLocal(createActionItemDto?.startDate.toString() || "")}
                    </DetailsValue>
                    <DetailsValue>
                        {toDateAndTimeFormatLocal(createActionItemDto?.dueDate.toString() || "")}
                    </DetailsValue>
                </Col>
            </Row>
        </ContentContainer>
    );

    const buildOrganisationalNodeAssociationModalBody = (): ReactElement<HTMLDivElement> => {
        return (
            <>
                <SbAlert
                    variant={"primary"}
                    text={t("ActionItemToHierarchyReportingNotificationMessage", {
                        keyPrefix: ActionItems,
                    })}
                />

                {renderActionItemDetails()}

                <LargeVerticalSpace />
                <AssignToHierarchyComponent
                    isNewActionItem={true}
                    nodeAssociationMode={ActionItemNodeAssociationMode.Organisational}
                    selectedNode={selectedNode}
                    setSelectedNode={setSelectedNode}
                />

                <LargeVerticalSpace />
                <EndAlignedDiv>
                    <Col sm={"auto"}>
                        <AssociateButton onClick={handleSaveActionItem} disabled={!selectedNode} />
                    </Col>
                    <Col sm={"auto"}>
                        <CancelButton onClick={() => setShowAssociateNodeModal(false)} />
                    </Col>
                </EndAlignedDiv>
            </>
        );
    };

    const buildOrganisationalNodeAssociationModalTitle = (): ReactElement<HTMLDivElement> => {
        return (
            <>
                <PageHeading>{t("AssociateNode", { keyPrefix: Nodes })}</PageHeading>
                <PageSubHeading>
                    {t("AssociateNodeHelpTextActionItemOrganisational", {
                        keyPrefix: ActionItems,
                    })}
                </PageSubHeading>
            </>
        );
    };

    return (
        <>
            <SbModal
                title={buildOrganisationalNodeAssociationModalTitle()}
                body={buildOrganisationalNodeAssociationModalBody()}
                isVisible={showAssociateNodeModal}
                updateIsVisible={setShowAssociateNodeModal}
                size={ModalSize.Large}
            />
            <PageHeading>{t("ActionItemCreate")}</PageHeading>
            <PageSubHeading>{t("CreateActionItemHeaderHelpText")}</PageSubHeading>
            <SectionVerticalSpace />
            <StyledHiddenOverflowForm onSubmit={handleShowAssociateNodeModal}>
                {renderActionItemDetail()}
                <SectionVerticalSpace />

                {renderActionItemTypes()}
                <SectionVerticalSpace />

                {actionItemTypeNodeId &&
                    getAdditionalFieldsResponseData &&
                    getAdditionalFieldsResponseData?.length > 0 &&
                    renderAdditionalInformation()}

                {renderRaciParticipants()}
                <SectionVerticalSpace />

                {renderOptionalInformation()}
                <SectionVerticalSpace />

                {renderNotificationTriggers()}
                <SectionVerticalSpace />

                <EndAlignedDiv>
                    <SbButton
                        type={"submit"}
                        variant={"primary"}
                        label={t("Next", { keyPrefix: Common })}
                        icon={FaArrowRight}
                    />
                    <CancelButton onClick={() => navigate(-1)} />
                </EndAlignedDiv>
            </StyledHiddenOverflowForm>
        </>
    );
};

export default CreateActionItemContainer;
