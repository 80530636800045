import { HTTPError } from "ky";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaArrowLeft, FaSave } from "react-icons/fa";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SingleValue } from "react-select";
import styled from "styled-components";
import { AssociateNodeToChecklistEvent } from "../../../core/constants/application-insights-events";
import {
    ChecklistQuestions,
    Common,
    Hierarchy,
    Nodes,
} from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    ContentContainer,
    EndAlignedDiv,
    LargeVerticalSpace,
    maxContentWidthSelectStyle,
    PageHeading,
    SectionVerticalSpace,
    StyledFormLabel,
    StyledHiddenOverflowForm,
} from "../../../core/theme/global-styles";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import {
    assignNodeValuesColumnNames,
    questionSetActionsColumnNames,
} from "../../../core/utilities/dataTableColumns";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesSuccessful,
    isMutationLoading,
} from "../../../core/utilities/responseStateHelper";
import { createAssociateChecklistNodeDto } from "../../../domain/dtos/checklists/associate-checklist-node-dto";
import CreateQuestionSetActionDto from "../../../domain/dtos/checklists/create-question-set-action-dto";
import { defaultPaginationDto, PaginationDto } from "../../../domain/dtos/common/pagination-dto";
import {
    ReactSelectDto,
    valuesToReactSelectDtos,
} from "../../../domain/dtos/common/react-select-dto";
import { NodeTypeValueDto } from "../../../domain/dtos/hierarchy/node-type-value-dto";
import { hasRoleTypeInGroup, Role } from "../../../domain/enums/Roles";
import { emptySearchChecklistAssociationRequest } from "../../../domain/requests/checklists/search-checklist-association-request";
import { Response } from "../../../domain/responses/common/response-response";
import {
    useAssociateNode,
    useGetAssignNodeTypeValueDetails,
} from "../../../domain/viewmodels/checklists/assign-node-type-value-viewmodel";
import { SbSelect } from "../../atoms/input/SbSelect";
import Paginator from "../../atoms/Paginator";
import { SbButton } from "../../atoms/SbButton";
import SbLabelText from "../../atoms/SbLabelText";
import { SbRibbon } from "../../atoms/SbRibbon";
import { translateText } from "../../helpers/translate";
import SbFormCheckFieldGroup from "../../molecules/input/SbFormCheckFieldGroup";
import SbFormDateTimeFieldGroup from "../../molecules/input/SbFormDateTimeFieldGroup";
import { SbFormSelectFieldGroup } from "../../molecules/input/SbFormSelectFieldGroup";
import { QuestionSetAction } from "../../molecules/QuestionSetAction";
import { SbAccordion } from "../../molecules/SbAccordion";
import { TextTitledPanel } from "../../molecules/SbPanel";
import { DataTable, StyledGridP, StyledTable } from "../../organisms/DataTable";

const StyledRow = styled(Row)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const appliesToUsersOptions: string[] = ["Any", "Each"];

const AssignNodeTypeValueContainer: React.FC = () => {
    const [questionSetsPaginationDto, setQuestionSetsPaginationDto] =
        useState<PaginationDto>(defaultPaginationDto);
    const [timeZoneSpecify, setTimeZoneSpecify] = useState(false);
    const [timeZoneNodeTypeValueId, setTimeZoneNodeTypeValueId] = useState<number | null>(null);
    const [selectedAppliesToUser, setSelectedAppliesToUser] = useState<string>("");
    const [selectedNodeTypeValues, setSelectedNodeTypeValues] = useState<number[]>([]);
    const [isTraining, setIsTraining] = useState(false);
    const [dateState, setDateState] = useState({
        activeDate: new Date(),
        expiryDate: null
    });

    const auth = useAuth();
    const menu = useMenu();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const [urlSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const navigateSearch = useNavigateSearch();

    const checklistId = Number(useParams().checklistId);
    const nodeId = Number(urlSearchParams.get("nodeId"));

    const searchRequest = {
        ...emptySearchChecklistAssociationRequest(),
        checklistId: checklistId,
    };

    const getAssignNodeTypeValueDetails = useGetAssignNodeTypeValueDetails(
        nodeId,
        checklistId,
        questionSetsPaginationDto,
        searchRequest
    );

    const nodeTypeValuesResponseData = getAssignNodeTypeValueDetails[0].data!;
    const checklistAssignmentNodeTypeValuesResponseData = getAssignNodeTypeValueDetails[1].data!;
    const adhocChecklistResponseData = getAssignNodeTypeValueDetails[2].data!;
    const adhocQuestionSetResponseData = getAssignNodeTypeValueDetails[3].data!;

    const associateNode = useAssociateNode();

    const { t } = useTranslation("translation");

    useLoader(
        areQueriesLoading(getAssignNodeTypeValueDetails) || isMutationLoading(associateNode),
        AssignNodeTypeValueContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Checklists);
    }, []);

    const onTimeZoneSpecifySelected = (item: SingleValue<NodeTypeValueDto>): void => {
        setTimeZoneNodeTypeValueId(item!.nodeTypeValueId);
    };

    const onAppliesToUserSelected = (item: SingleValue<{ value: string; label: string }>): void => {
        item && setSelectedAppliesToUser(item.value);
    };

    const handleIsTrainingChange = (_: string, checked: boolean): void => {
        setIsTraining(checked);
    };

    const selectNodeTypeValue = (nodeTypeValueId: number): void => {
        const selectedNodeTypeValue = nodeTypeValueId;
        const selectedNodeTypeValueArr = [...selectedNodeTypeValues];

        const index = selectedNodeTypeValueArr.indexOf(selectedNodeTypeValue);

        if (index > -1) {
            selectedNodeTypeValueArr.splice(index, 1);
        } else {
            selectedNodeTypeValueArr.push(selectedNodeTypeValue);
        }

        setSelectedNodeTypeValues(selectedNodeTypeValueArr);
    };

    const selectAllNodeTypeValues = (nodeTypeValueIds: number[]): void => {
        if (selectedNodeTypeValues.length == nodeTypeValueIds.length) {
            setSelectedNodeTypeValues([]);
        } else {
            setSelectedNodeTypeValues([...nodeTypeValueIds]);
        }
    };

    const checkSelectedNodeTypeValues = (nodeTypeValueId: number): boolean => {
        const selectedNodeTypeValue = nodeTypeValueId;
        const selectedNodeTypeValueArr = [...selectedNodeTypeValues];

        return selectedNodeTypeValueArr.includes(selectedNodeTypeValue);
    };

    const checkAllSelectedNodeTypeValues = (nodeTypeValueIds: number[]): boolean => {
        return selectedNodeTypeValues.length == nodeTypeValueIds.length;
    };

    const navigateToViewQuestionSetDetails = (id: number): void =>
        navigate(`${getPath(AccordionTitles.QuestionSets)}/${id}`);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData = new FormData(event.currentTarget);

        let createQuestionSetActionDtos: CreateQuestionSetActionDto[] = [];

        adhocQuestionSetResponseData.rows.map((x) => {
            if (
                formData.get(`Calendar Entry ${x.metadata}`) === "on" ||
                formData.get(`Review ${x.metadata}`) === "on"
            ) {
                createQuestionSetActionDtos.push(
                    new CreateQuestionSetActionDto(
                        x.metadata!,
                        formData.get(`Calendar Entry ${x.metadata}`) === "on",
                        formData.get(`Review ${x.metadata}`) === "on",
                        new Date(formData.get(`startDate ${x.metadata}`) as string),
                        new Date(formData.get(`endDate ${x.metadata}`) as string)
                    )
                );
            }
        });

        associateNode.mutate(
            createAssociateChecklistNodeDto(
                checklistId,
                nodeId,
                selectedNodeTypeValues,
                timeZoneNodeTypeValueId,
                selectedAppliesToUser,
                auth.userId!,
                formData,
                createQuestionSetActionDtos
            ),
            {
                onSuccess: async (response: Response<number | null>) => {
                    trackAppInsightsEvent(
                        auth.email,
                        window.location.href,
                        AssociateNodeToChecklistEvent
                    );

                    const params = [
                        createNavigateSearchParameter("nodeId", nodeId.toString()),
                        createNavigateSearchParameter("checklistId", checklistId.toString()),
                        createNavigateSearchParameter("success", "true"),
                        createNavigateSearchParameter(
                            "messageKey",
                            "SuccessfullyAssociatedNodeToChecklist"
                        ),
                    ];

                    navigateSearch(
                        `${getPath(AccordionTitles.Checklists)}/${
                            response.data
                        }/checklist-assignment-details`,
                        params
                    );
                },
                onError: (error: HTTPError) => {
                    trackAppInsightsException(
                        auth.email,
                        window.location.href,
                        AssociateNodeToChecklistEvent,
                        error
                    );
                    errorResponseToDisplayHandler(error);
                },
            }
        );
    };

    const handleDateChange = (name: string, value: any) => {
        setDateState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      };

    const renderTimeZoneSection = (): JSX.Element => (
        <Form.Group as={Row} className="mb-3">
            <StyledFormLabel column sm={2}>
                <SbLabelText label={t("TimeZone", { keyPrefix: Common })} />
            </StyledFormLabel>
            <Col sm={4}>
                <Form.Check
                    type={"radio"}
                    name={"timezone"}
                    id={"fromHierarchy"}
                    label={t("FromHierarchy", { keyPrefix: Hierarchy })}
                    onClick={() => {
                        setTimeZoneSpecify(false);
                        setTimeZoneNodeTypeValueId(null);
                    }}
                    defaultChecked
                />
                <StyledRow>
                    <Col sm={4}>
                        <Form.Check
                            type={"radio"}
                            name={"timezone"}
                            id={"specify"}
                            label={t("Specify", { keyPrefix: Common })}
                            onClick={() => setTimeZoneSpecify(true)}
                        />
                    </Col>
                    <Col>
                        <SbSelect
                            name={"appliesToUsers"}
                            placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                            searchable
                            styles={maxContentWidthSelectStyle}
                            clearable
                            items={nodeTypeValuesResponseData}
                            itemLabel={(option: NodeTypeValueDto) => option.value} //TODO: Add translations for dynamic data
                            itemValue={(option: NodeTypeValueDto) =>
                                option.nodeTypeValueId.toString()
                            }
                            onChange={onTimeZoneSpecifySelected}
                            disabled={!timeZoneSpecify}
                        />
                    </Col>
                </StyledRow>
            </Col>
        </Form.Group>
    );

    const renderQuestionSetActions = (): JSX.Element => (
        <SbAccordion title={t("QuestionSetActions", { keyPrefix: ChecklistQuestions })}>
            <ContentContainer>
                <StyledTable hover borderless>
                    {questionSetActionsColumnNames && (
                        <thead>
                            <tr>
                                {questionSetActionsColumnNames.map((column) => (
                                    <th key={column.value}>
                                        <StyledGridP>{column.value}</StyledGridP>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                    )}

                    <tbody>
                        {adhocQuestionSetResponseData.rows.map((x) => (
                            <QuestionSetAction
                                id={x.metadata!}
                                name={x.columns[0].value}
                                navigateToViewQuestionSetDetails={navigateToViewQuestionSetDetails}
                            />
                        ))}
                    </tbody>
                </StyledTable>
            </ContentContainer>
            <EndAlignedDiv>
                <Paginator
                    totalItems={adhocQuestionSetResponseData.recordCount}
                    paginationDto={questionSetsPaginationDto}
                    setPaginationProps={setQuestionSetsPaginationDto}
                />
            </EndAlignedDiv>
        </SbAccordion>
    );

    return areQueriesSuccessful(getAssignNodeTypeValueDetails) ? (
        <>
            <PageHeading>{t("AssignmentOptions", { keyPrefix: Common })}</PageHeading>
            <SectionVerticalSpace />

            <StyledHiddenOverflowForm onSubmit={handleSubmit}>
                <TextTitledPanel title={t("AssignmentOptions", { keyPrefix: Common })}>
                    {isTraining && (
                        <SbRibbon size={"large"} label={t("Training", { keyPrefix: Common })} />
                    )}

                    <SbFormDateTimeFieldGroup
                        name="activeDate"
                        label={t("ActiveDateTime", { keyPrefix: ChecklistQuestions })}
                        defaultValue={dateState.activeDate}
                        onInputChange={(value) => handleDateChange("activeDate", value)}
                        required
                        excludeTime
                    />

                    <SbFormDateTimeFieldGroup
                        name="expiryDate"
                        label={t("ExpiryDateTime", { keyPrefix: ChecklistQuestions })}
                        defaultValue={dateState.expiryDate}
                        onInputChange={(value) => handleDateChange("expiryDate", value)}
                        excludeTime
                    />

                    {renderTimeZoneSection()}

                    <SbFormSelectFieldGroup
                        name={"appliesToUsers"}
                        label={t("AppliesToUsers", { keyPrefix: ChecklistQuestions })}
                        placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                        searchable
                        clearable
                        required
                        items={valuesToReactSelectDtos(appliesToUsersOptions)}
                        itemDisplayText={(option: ReactSelectDto<string>) =>
                            translateText(t, option.label, Common)
                        }
                        onChange={onAppliesToUserSelected}
                    />

                    <SbFormCheckFieldGroup
                        fieldLabel={t("AllowActionItemTrigger", { keyPrefix: ChecklistQuestions })}
                        type="checkbox"
                        values={[
                            {
                                name: "allowActionItemTrigger",
                            },
                        ]}
                    />

                    {adhocChecklistResponseData && (
                        <>
                            <SbFormCheckFieldGroup
                                fieldLabel={t("AllowAdhocIntervalOverride", {
                                    keyPrefix: ChecklistQuestions,
                                })}
                                type="checkbox"
                                values={[
                                    {
                                        name: "allowAdhocIntervalOverride",
                                    },
                                ]}
                            />
                            <SbFormCheckFieldGroup
                                fieldLabel={t("AllowCustomNotifications", {
                                    keyPrefix: ChecklistQuestions,
                                })}
                                type="checkbox"
                                values={[
                                    {
                                        name: "allowCustomNotifications",
                                    },
                                ]}
                            />
                        </>
                    )}

                    <SbFormCheckFieldGroup
                        fieldLabel={t("IsTrainingDisplay", { keyPrefix: Common })}
                        type="checkbox"
                        values={[
                            {
                                name: "isTraining",
                                onChangeHandler: handleIsTrainingChange,
                                defaultSelected: isTraining,
                            },
                        ]}
                    />

                    <SbFormCheckFieldGroup
                        fieldLabel={t("AllowFIleAttachment", { keyPrefix: Common })}
                        type="checkbox"
                        values={[
                            {
                                name: "allowFileAttachment",
                            },
                        ]}
                    />

                    <DataTable
                        keyPrefix={Hierarchy}
                        noResultsMessage={`${t("NoNodeTypeValuesFound", { keyPrefix: Nodes })}`}
                        columns={assignNodeValuesColumnNames}
                        rows={checklistAssignmentNodeTypeValuesResponseData}
                        selectItem={selectNodeTypeValue}
                        selectAll={selectAllNodeTypeValues}
                        selectedItem={checkSelectedNodeTypeValues}
                        selectedAllItems={checkAllSelectedNodeTypeValues}
                    />
                </TextTitledPanel>
                <LargeVerticalSpace />

                {hasRoleTypeInGroup(auth.userRoles, [Role.Monitoring]) &&
                    adhocChecklistResponseData && (
                        <>
                            {renderQuestionSetActions()}
                            <LargeVerticalSpace />
                        </>
                    )}

                <EndAlignedDiv>
                    <Col sm={"auto"}>
                        <SbButton
                            icon={FaArrowLeft}
                            variant={"secondary"}
                            type={"button"}
                            label={t("Back", { keyPrefix: Common })}
                            onClick={() => navigate(-1)}
                        />
                    </Col>
                    <Col sm={"auto"}>
                        <SbButton
                            icon={FaSave}
                            variant={"primary"}
                            type="submit"
                            label={t("Assign", { keyPrefix: Common })}
                        />
                    </Col>
                </EndAlignedDiv>
            </StyledHiddenOverflowForm>
        </>
    ) : (
        <></>
    );
};

export default AssignNodeTypeValueContainer;
