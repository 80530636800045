import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { SingleValue } from "react-select";
import {
    ActionItems,
    ActionItemTypes,
    ChecklistQuestions,
    Common,
} from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesSuccessful,
    isMutationLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import {
    ActionItemTypeDto,
    createActionItemTypeDto,
} from "../../../domain/dtos/action-items/action-item-type-dto";
import { EditActionItemSubTypeNodeDto } from "../../../domain/dtos/questions/edit-action-item-sub-type-node-dto";
import {
    useEditActionItemSubTypeNode,
    useGetActionItemTypesAndSubTypeDetails,
} from "../../../domain/viewmodels/questions/edit-action-item-sub-type-node-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import { SbFormSelectFieldGroup } from "../../molecules/input/SbFormSelectFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

const EditActionItemSubTypeNodeContainer: React.FC = () => {
    const questionId = Number(useParams().questionId);

    const [actionItemTypeNode, setActionItemTypeNode] = useState<ActionItemTypeDto | null>(null);
    const [actionItemSubTypeNode, setActionItemSubTypeNode] = useState<ActionItemTypeDto | null>();

    const menu = useMenu();
    const navigate = useNavigate();
    const navigateSearch = useNavigateSearch();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const { t } = useTranslation("translation", { keyPrefix: ActionItems });

    const editActionItemSubTypeNode = useEditActionItemSubTypeNode();

    const getActionItemTypesAndSubTypeDetails = useGetActionItemTypesAndSubTypeDetails(
        questionId,
        actionItemTypeNode?.nodeId!
    );

    const getActionItemTypes = getActionItemTypesAndSubTypeDetails[0];
    const getActionItemSubTypes = getActionItemTypesAndSubTypeDetails[1];
    const getActionItemTypeAndSubTypeDetails = getActionItemTypesAndSubTypeDetails[2];

    const getActionItemTypesResponseData = getActionItemTypes.data;
    const getActionItemSubTypesResponseData = getActionItemSubTypes.data;

    useLoader(
        areQueriesLoading(getActionItemTypesAndSubTypeDetails) ||
            isMutationLoading(editActionItemSubTypeNode),
        EditActionItemSubTypeNodeContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Questions);
    }, []);

    useEffect(() => {
        if (isQuerySuccessful(getActionItemTypeAndSubTypeDetails)) {
            setActionItemTypeNode(
                createActionItemTypeDto(
                    getActionItemTypeAndSubTypeDetails!.data?.actionItemTypeNodeId!,
                    0,
                    getActionItemTypeAndSubTypeDetails!.data?.actionItemTypeName!
                )
            );
            setActionItemSubTypeNode(
                createActionItemTypeDto(
                    getActionItemTypeAndSubTypeDetails!.data?.actionItemTypeSubTypeNodeId!,
                    0,
                    getActionItemTypeAndSubTypeDetails!.data?.actionItemTypeSubTypeTypeName!
                )
            );
        }
    }, [getActionItemTypeAndSubTypeDetails.fetchStatus]);

    const onChangeActionTypeValue = (option: SingleValue<ActionItemTypeDto>): void => {
        setActionItemTypeNode(option!);
        setActionItemSubTypeNode(null);
    };

    const onChangeActionSubTypeValue = (option: SingleValue<ActionItemTypeDto>): void => {
        setActionItemSubTypeNode(option!);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        editActionItemSubTypeNode.mutate(
            new EditActionItemSubTypeNodeDto(questionId, actionItemSubTypeNode!.nodeId),
            {
                onSuccess: async () => {
                    const params = [
                        createNavigateSearchParameter("success", "true"),
                        createNavigateSearchParameter(
                            "messageKey",
                            "SuccessfullyEditedActionItemSubTypeNode"
                        ),
                    ];

                    navigateSearch(`${getPath(AccordionTitles.Questions)}/${questionId}`, params);
                },
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    return (
        <>
            <PageHeading>
                {t("ActionItemTypeNodeEditHeading", { keyPrefix: ChecklistQuestions })}
            </PageHeading>
            <SectionVerticalSpace />

            {areQueriesSuccessful([...getActionItemTypesAndSubTypeDetails]) && (
                <>
                    <TextTitledPanel title={t("ActionItemType", { keyPrefix: ActionItemTypes })}>
                        <Form onSubmit={handleSubmit}>
                            <SbFormSelectFieldGroup
                                name={"actionItemType"}
                                label={t("ActionItemType")}
                                placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                                required
                                searchable
                                clearable={false}
                                items={getActionItemTypesResponseData}
                                itemDisplayText={(option: ActionItemTypeDto) => option.value}
                                onChange={onChangeActionTypeValue}
                                defaultSelectedItem={actionItemTypeNode}
                            />

                            <SbFormSelectFieldGroup
                                name={"actionItemSubType"}
                                label={t("ActionItemSubType", { keyPrefix: ActionItemTypes })}
                                placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                                required
                                searchable
                                clearable={false}
                                items={getActionItemSubTypesResponseData}
                                itemDisplayText={(option: ActionItemTypeDto) => option.value}
                                onChange={onChangeActionSubTypeValue}
                                value={actionItemSubTypeNode}
                                defaultSelectedItem={actionItemSubTypeNode}
                            />

                            <EndAlignedDiv>
                                <SaveButton type={"submit"} />
                                <CancelButton
                                    onClick={() =>
                                        navigate(
                                            `${getPath(AccordionTitles.Questions)}/${questionId}`
                                        )
                                    }
                                />
                            </EndAlignedDiv>
                        </Form>
                    </TextTitledPanel>
                </>
            )}
        </>
    );
};

export default EditActionItemSubTypeNodeContainer;
