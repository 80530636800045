import { NavigateFunction } from "react-router-dom";
import { AuthContextType } from "../store/auth-context";
import { getPath } from "./getPath";

export const getNavbarDropdownItemLink = (
    item: string,
    auth: AuthContextType,
    navigate: NavigateFunction,
    checkForLatestWizardNotification?: () => void
): Window | null | string | void | undefined => {
    switch (item) {
        // Help
        case "ViewLatestUpdate":
            return checkForLatestWizardNotification && checkForLatestWizardNotification();
        case "UpdatesHistory":
            return navigate(`${getPath("Wizard Notification History")}/${auth.userId}`);
        case "UserGuide":
            return window.location.replace("ControlFirst User Guide.pptx");
        case "ActionTrackerUserGuide":
            return window.location.replace("Action Tracker User Guide.pptx");
        case "AdhocChecklists":
            return window.location.replace("ControlFirst - Adhoc checklist.pptx");
        case "BUSAUserGuide":
            return window.location.replace("BUSA User Guide.pptx");
        case "TrainingVideos":
            return window.open(
                "https://web.microsoftstream.com/embed/channel/d680e288-533d-4204-b4a1-4ebea6f7d10f?sort=date"
            );
        case "ProductSpecialistOverview":
            return window.location.replace("Product Specialist Overview.pptx");
        // Support
        case "LogTicketOnRemedy":
            return window.open(process.env.REACT_APP_REMEDY_URL);
        case "RemedyGuide":
            return window.location.replace("Logging ControlFirst Request on Remedy.pdf");
        // Profile
        case "Profile":
            return navigate("profile");
        case "LogOff":
            return auth.logOut();
        default:
            return "/";
    }
};
