import { UseMutationResult, UseQueryResult, useMutation, useQueries } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    getActionItemSubTypesByActionItemTypeNodeId,
    getActionItemTypes,
} from "../../../data/services/action-items/action-item-types-service";
import {
    associatedActionItemSubTypeNode,
    editActionItemSubTypeNode,
} from "../../../data/services/questions/question-service";
import {
    ActionItemTypeDto,
    toActionItemTypeDtos,
} from "../../dtos/action-items/action-item-type-dto";
import { ActionItemTypeAndSubTypeDto } from "../../dtos/questions/action-item-type-and-sub-type-dto";
import { EditActionItemSubTypeNodeDto } from "../../dtos/questions/edit-action-item-sub-type-node-dto";
import { EditActionItemSubTypeNodeRequest } from "../../requests/questions/edit-action-item-sub-type-node-request";
import { Response } from "../../responses/common/response-response";

export const useGetActionItemTypesAndSubTypeDetails = (
    questionId: number,
    actionItemTypeNodeId: number | null
): [
    UseQueryResult<ActionItemTypeDto[], HTTPError>,
    UseQueryResult<ActionItemTypeDto[], HTTPError>,
    UseQueryResult<ActionItemTypeAndSubTypeDto, HTTPError>,
] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["getActionItemTypes"],
                queryFn: () => getActionItemTypes(url.baseUrl),
                select: toActionItemTypeDtos,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getActionItemSubTypesByActionItemId", actionItemTypeNodeId],
                enabled: actionItemTypeNodeId != null,
                queryFn: () =>
                    getActionItemSubTypesByActionItemTypeNodeId(url.baseUrl, actionItemTypeNodeId!),
                select: toActionItemTypeDtos,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getAssociatedActionItemTypeNode", questionId],
                queryFn: () => associatedActionItemSubTypeNode(url.baseUrl, questionId),
                select: ActionItemTypeAndSubTypeDto.constructFromResponse,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<ActionItemTypeDto[], HTTPError>,
        UseQueryResult<ActionItemTypeDto[], HTTPError>,
        UseQueryResult<ActionItemTypeAndSubTypeDto, HTTPError>,
    ];
};

export const useEditActionItemSubTypeNode = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    EditActionItemSubTypeNodeDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: EditActionItemSubTypeNodeDto) => {
        const request = new EditActionItemSubTypeNodeRequest(
            mutationKey.questionId,
            mutationKey.actionItemSubTypeNodeId
        );

        return editActionItemSubTypeNode(url.baseUrl, request);
    });
};
