import { useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import {
    associatedActionItemSubTypeNode,
    filterAlternativeQuestionTexts,
    filterAssociatedQuestionSets,
    filterAssociatedTaxonomyNodes,
    filterNestedQuestions,
    getQuestionDetails,
} from "../../../data/services/questions/question-service";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { PaginationDto } from "../../dtos/common/pagination-dto";
import {
    createQuestionDetailsDto,
    QuestionDetailsDto,
} from "../../dtos/questions/question-details-dto";
import { createPaginationRequestFromDto } from "../../requests/common/pagination-request";
import { FilterNestedQuestionsRequest } from "../../requests/questions/filter-nested-questions-request";
import { CreateQuestionAssociationSearchRequest } from "../../requests/questions/filter-question-association-request";
import { SearchQuestionAssociationRequest } from "../../requests/questions/search-question-association-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { QuestionSetResponse } from "../../responses/question-sets/question-set-response";
import { ActionItemTypeAndSubTypeResponse } from "../../responses/questions/action-item-type-and-sub-type-response";
import { AlternativeQuestionTextResponse } from "../../responses/questions/alternative-question-text-response";
import { NestedQuestionResponse } from "../../responses/questions/nested-question-response";
import { QuestionDetailsResponse } from "../../responses/questions/question-details-response";
import { QuestionTaxonomyLinksResponse } from "../../responses/questions/question-taxonomy-links-response";

export const useGetQuestionDetails = (
    questionId: number,
    nestedQuestionPaginationDto: PaginationDto,
    taxonomyNodesPaginationDto: PaginationDto,
    alternativeQuestionTextPaginationDto: PaginationDto,
    questionSetsPaginationDto: PaginationDto,
    searchRequest: SearchQuestionAssociationRequest
): [
    UseQueryResult<QuestionDetailsDto, HTTPError>,
    UseQueryResult<PaginatedTableDto<number>, HTTPError>,
    UseQueryResult<PaginatedTableDto<number>, HTTPError>,
    UseQueryResult<PaginatedTableDto<number>, HTTPError>,
    UseQueryResult<PaginatedTableDto<number>, HTTPError>,
    UseQueryResult<PaginatedTableDto<number>, HTTPError>,
] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["getQuestionDetails", questionId],
                queryFn: () => getQuestionDetails(url.baseUrl, questionId),
                select: toQuestionDetailsDto,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["filterNestedQuestions", questionId, nestedQuestionPaginationDto],
                queryFn: () =>
                    filterNestedQuestions(
                        url.baseUrl,
                        new FilterNestedQuestionsRequest(questionId, nestedQuestionPaginationDto)
                    ),
                keepPreviousData: true,
                select: transformToNestedQuestionsRows,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getAssociatedTaxonomyNodes", taxonomyNodesPaginationDto, searchRequest],
                queryFn: () =>
                    filterAssociatedTaxonomyNodes(
                        url.baseUrl,
                        CreateQuestionAssociationSearchRequest(
                            createPaginationRequestFromDto(taxonomyNodesPaginationDto),
                            searchRequest
                        )
                    ),
                keepPreviousData: true,
                select: transformToTaxonomyNodesDataTableRows,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: [
                    "getAlternativeQuestionTexts",
                    alternativeQuestionTextPaginationDto,
                    searchRequest,
                ],
                queryFn: () =>
                    filterAlternativeQuestionTexts(
                        url.baseUrl,
                        CreateQuestionAssociationSearchRequest(
                            createPaginationRequestFromDto(alternativeQuestionTextPaginationDto),
                            searchRequest
                        )
                    ),
                keepPreviousData: true,
                select: transformToAlternativeQuestionsTextsDataTableRows,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: [
                    "filterAssociatedQuestionSets",
                    questionSetsPaginationDto,
                    searchRequest,
                ],
                queryFn: () =>
                    filterAssociatedQuestionSets(
                        url.baseUrl,
                        CreateQuestionAssociationSearchRequest(
                            createPaginationRequestFromDto(questionSetsPaginationDto),
                            searchRequest
                        )
                    ),
                keepPreviousData: true,
                select: transformToAssociatedQuestionSetsDataTableRows,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getAssociatedActionItemTypeNode", questionId],
                queryFn: () => associatedActionItemSubTypeNode(url.baseUrl, questionId),
                keepPreviousData: true,
                select: transformToActionItemTypeNodeDataTableRows,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<QuestionDetailsDto, HTTPError>,
        UseQueryResult<PaginatedTableDto<number>, HTTPError>,
        UseQueryResult<PaginatedTableDto<number>, HTTPError>,
        UseQueryResult<PaginatedTableDto<number>, HTTPError>,
        UseQueryResult<PaginatedTableDto<number>, HTTPError>,
        UseQueryResult<PaginatedTableDto<number>, HTTPError>,
    ];
};

const toQuestionDetailsDto = (response: Response<QuestionDetailsResponse>): QuestionDetailsDto =>
    createQuestionDetailsDto(response);

const transformToNestedQuestionsRows = (
    response: Response<PaginationResponse<NestedQuestionResponse>>
): PaginatedTableDto<number> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results.map((nestedQuestion) => ({
        metadata: nestedQuestion.nestedQuestionId,
        columns: [
            {
                // Order Index
                value: nestedQuestion.orderIndex?.toString(),
                type: DataTableColumnTypes.Text,
                metadata: "OrderIndex",
            },
            {
                // ID
                value: nestedQuestion.questionId.toString(),
                type: DataTableColumnTypes.Link,
                metadata: "ID",
            },
            {
                // Question Text
                value: nestedQuestion.questionText.text,
                type: DataTableColumnTypes.Text,
                metadata: "QuestionText",
            },
            {
                // Category
                value: nestedQuestion.questionCategory?.name,
                type: DataTableColumnTypes.Text,
                metadata: "Category",
            },
            {
                // Type
                value: nestedQuestion.questionType?.name,
                type: DataTableColumnTypes.Text,
                metadata: "Type",
            },
            {
                // Answer Type
                value: nestedQuestion.answerTypeResponse?.name,
                type: DataTableColumnTypes.Text,
                metadata: "AnswerType",
            },
            {
                // Render Mode
                value: nestedQuestion.renderInline ? "Inline" : "Default",
                type: DataTableColumnTypes.Text,
                metadata: "RenderMode",
            },
            {
                // Parent Question Answer Trigger
                value:
                    nestedQuestion.parentQuestionAnswerTrigger?.toString() === ""
                        ? "No"
                        : nestedQuestion.parentQuestionAnswerTrigger?.toString(),
                type: DataTableColumnTypes.Text,
                metadata: "ParentQuestionAnswerTrigger",
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};

const transformToTaxonomyNodesDataTableRows = (
    response: Response<PaginationResponse<QuestionTaxonomyLinksResponse>>
): PaginatedTableDto<number> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((taxonomyNode) => ({
        metadata: taxonomyNode.nodeId,
        columns: [
            {
                value: `${taxonomyNode.nodeType.name} - ${taxonomyNode.nodeTypeValue?.value}`,
                type: DataTableColumnTypes.Link,
            },
            {
                value: taxonomyNode.path,
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};

const transformToAlternativeQuestionsTextsDataTableRows = (
    response: Response<PaginationResponse<AlternativeQuestionTextResponse>>
): PaginatedTableDto<number> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((alternativeQuestionsText) => ({
        metadata: alternativeQuestionsText.questionTextId,
        columns: [
            {
                value: alternativeQuestionsText.language.name,
                type: DataTableColumnTypes.Text,
            },
            {
                value: alternativeQuestionsText.text,
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};

const transformToAssociatedQuestionSetsDataTableRows = (
    response: Response<PaginationResponse<QuestionSetResponse>>
): PaginatedTableDto<number> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((questionSet) => ({
        metadata: questionSet.questionSetId,
        columns: [
            {
                // Name
                value: questionSet.name.toString(),
                type: DataTableColumnTypes.Link,
            },
            {
                // Description
                value: questionSet.description?.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                // Process
                value: questionSet.process?.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                // Schedule Description
                value: questionSet.questionSetDefaultScheduleResponse?.scheduleDescription?.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                // Questions Count
                value: questionSet.activeQuestionsCount.toString(),
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};

const transformToActionItemTypeNodeDataTableRows = (
    response: Response<PaginationResponse<ActionItemTypeAndSubTypeResponse>>
): PaginatedTableDto<number> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((actionItemSubTypeNode) => ({
        metadata: actionItemSubTypeNode.actionItemTypeSubTypeNodeId,
        columns: [
            {
                value: actionItemSubTypeNode.actionItemTypeName,
                type: DataTableColumnTypes.Text,
            },
            {
                value: actionItemSubTypeNode.actionItemTypeSubTypeTypeName,
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};
