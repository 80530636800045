import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { ActionItemTypeAndSubTypeResponse } from "../../responses/questions/action-item-type-and-sub-type-response";

export class ActionItemTypeAndSubTypeDto {
    actionItemTypeNodeId: number;
    actionItemTypeName: string;
    actionItemTypeSubTypeNodeId: number;
    actionItemTypeSubTypeTypeName: string;

    public constructor(response: ActionItemTypeAndSubTypeResponse) {
        this.actionItemTypeNodeId = response.actionItemTypeNodeId;
        this.actionItemTypeName = response.actionItemTypeName;
        this.actionItemTypeSubTypeNodeId = response.actionItemTypeSubTypeNodeId;
        this.actionItemTypeSubTypeTypeName = response.actionItemTypeSubTypeTypeName;
    }

    public static constructFromResponse = (
        response: Response<PaginationResponse<ActionItemTypeAndSubTypeResponse>>
    ): ActionItemTypeAndSubTypeDto => new ActionItemTypeAndSubTypeDto(response.data.results[0]);
}
