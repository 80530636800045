import { DefaultActionItemSubTypeNodeResponse } from "../../responses/questions/default-action-item-sub-type-node-response";

export class DefaultActionItemSubTypeNodeDto {
    defaultActionItemSubTypeNodeId: number;
    defaultActionItemSubTypeNodeName: string;

    public constructor(response: DefaultActionItemSubTypeNodeResponse) {
        this.defaultActionItemSubTypeNodeId = response.defaultActionItemSubTypeNodeId;
        this.defaultActionItemSubTypeNodeName = response.defaultActionItemSubTypeNodeName;
    }
}
