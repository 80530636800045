import { UseMutationResult, UseQueryResult, useMutation, useQueries } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    getActionItemSubTypesByActionItemTypeNodeId,
    getActionItemTypes,
} from "../../../data/services/action-items/action-item-types-service";
import { associateActionItemSubTypeNode } from "../../../data/services/questions/question-service";
import {
    ActionItemTypeDto,
    toActionItemTypeDtos,
} from "../../dtos/action-items/action-item-type-dto";
import { AssociateActionItemSubTypeNodeDto } from "../../dtos/questions/associate-action-item-sub-type-node-dto";
import { AssociateActionItemSubTypeNodeRequest } from "../../requests/questions/associate-action-item-sub-type-node-request";
import { Response } from "../../responses/common/response-response";

export const useGetActionItemTypesAndSubTypes = (
    actionItemTypeNodeId: number | null
): [
    UseQueryResult<ActionItemTypeDto[], HTTPError>,
    UseQueryResult<ActionItemTypeDto[], HTTPError>,
] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["getActionItemTypes"],
                queryFn: () => getActionItemTypes(url.baseUrl),
                select: toActionItemTypeDtos,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getActionItemSubTypesByActionItemId", actionItemTypeNodeId],
                enabled: actionItemTypeNodeId != null,
                queryFn: () =>
                    getActionItemSubTypesByActionItemTypeNodeId(url.baseUrl, actionItemTypeNodeId!),
                select: toActionItemTypeDtos,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<ActionItemTypeDto[], HTTPError>,
        UseQueryResult<ActionItemTypeDto[], HTTPError>,
    ];
};

export const useAssociateActionItemSubTypeNode = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    AssociateActionItemSubTypeNodeDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: AssociateActionItemSubTypeNodeDto) => {
        const request = new AssociateActionItemSubTypeNodeRequest(
            mutationKey.questionId,
            mutationKey.actionItemSubTypeNodeId
        );

        return associateActionItemSubTypeNode(url.baseUrl, request);
    });
};
