import { localDateSerialiserReplacer } from "../../../core/utilities/serialiser";
import { ActionItemInstanceCommentRequest } from "../../../domain/requests/action-items/action-item-instance-comment-request";
import { CompleteActionItemRequest } from "../../../domain/requests/action-items/complete-action-item-request";
import { ExtendActionItemRequest } from "../../../domain/requests/action-items/extend-action-item-request";
import {
    buildFilterSearchFinalizedActionItemsParameters,
    FilterFinalizedActionItemsRequest,
} from "../../../domain/requests/action-items/filter-finalized-action-items-request";
import {
    buildFilterSearchMyActionItemsParameters,
    FilterMyActionItemsRequest,
} from "../../../domain/requests/action-items/filter-my-action-items-request";
import GenerateQuestionAnswerActionItemRequest from "../../../domain/requests/action-items/generate-question-answer-action-item-request";
import { PlaceOnHoldActionItemRequest } from "../../../domain/requests/action-items/place-on-hold-action-item-request";
import { ReactivateOnHoldActionItemRequest } from "../../../domain/requests/action-items/reactivate-on-hold-action-item-request";
import { ReopenFinalizedActionItemRequest } from "../../../domain/requests/action-items/reopen-finalized-action-item-request";
import { ActionItemInstanceFileUploadRequest } from "../../../domain/requests/actions/action-item-instance-file-upload-request";
import {
    ActionItemRaciParticipantDetailsRequest,
    constructRaciParticipantDetailsSearchParamsFromActionItem,
} from "../../../domain/requests/actions/action-item-raci-participant-details-request";
import { AssociateDissociateReferentialLinkToActionItemRequest } from "../../../domain/requests/actions/associate-dissociate-referential-link-to-action-item-request";
import { AssociateNodeToActionItemRequest } from "../../../domain/requests/actions/associate-node-to-action-item-request";
import { AssociateRaciParticipantRequest } from "../../../domain/requests/actions/associate-raci-participant-request";
import { CancelActionItemRequest } from "../../../domain/requests/actions/cancel-action-item-request";
import { CreateActionItemRequest } from "../../../domain/requests/actions/create-action-item-request";
import { DissociateNodeToActionItemRequest } from "../../../domain/requests/actions/dissociate-node-to-action-item-request";
import { DissociateRaciParticipantRequest } from "../../../domain/requests/actions/dissociate-raci-participant-request";
import { EditActionItemRequest } from "../../../domain/requests/actions/edit-action-item-request";
import {
    buildFilterActionItemAssociationSearchParameters,
    FilterActionItemAssociationRequest,
} from "../../../domain/requests/actions/filter-action-item-association-request";
import {
    buildFilterActionItemNodeAssociationSearchParameters,
    FilterActionItemNodeAssociationRequest,
} from "../../../domain/requests/actions/filter-action-item-node-association-request";
import {
    buildFilterSearchActionItemsParameters,
    FilterActionItemsRequest,
} from "../../../domain/requests/actions/filter-action-items-request";
import {
    buildAssociatedRaciSetUsersSearchParameters,
    FilterAssociatedRaciSetUsersRequest,
} from "../../../domain/requests/actions/filter-associated-raci-set-users-request";
import { ActionItemDynamicFieldDetailsResponse } from "../../../domain/responses/action-items/action-item-dynamic-field-details-response";
import { ActionItemDynamicFieldValueResponse } from "../../../domain/responses/action-items/action-item-dynamic-field-values-response";
import { ActionItemInstanceCommentResponse } from "../../../domain/responses/action-items/action-item-instance-comment-response";
import ActionItemInstanceFileDownloadResponse from "../../../domain/responses/action-items/action-item-instance-file-download-response";
import { MyActionItemResponse } from "../../../domain/responses/action-items/my-action-item-response";
import { ActionItemDetailsResponse } from "../../../domain/responses/actions/action-item-details-response";
import { ActionItemReferentialLinkResponse } from "../../../domain/responses/actions/action-item-referential-link-response";
import { ActionItemResponse } from "../../../domain/responses/actions/action-item-response";
import { RaciSetUserResponse } from "../../../domain/responses/actions/raci-set-user-response";
import { PaginationResponse } from "../../../domain/responses/common/pagination-response";
import { Response } from "../../../domain/responses/common/response-response";
import { BaseNodeDetailsResponse } from "../../../domain/responses/hierarchy/base-node-details-response";
import client from "../../http-client";

const route = "actionitems";

export const filterActionItems = async (
    prefixUrl: string,
    request: FilterActionItemsRequest
): Promise<Response<PaginationResponse<ActionItemResponse>>> => {
    const searchParams = buildFilterSearchActionItemsParameters(request);

    return await client
        .get(`${route}/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const filterMyActionItems = async (
    prefixUrl: string,
    request: FilterMyActionItemsRequest
): Promise<Response<PaginationResponse<MyActionItemResponse>>> => {
    const searchParams = buildFilterSearchMyActionItemsParameters(request);

    return await client
        .get(`${route}/filter-my-action-items`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const createActionItem = async (
    prefixUrl: string,
    request: CreateActionItemRequest
): Promise<Response<ActionItemResponse>> =>
    await client
        .post(`${route}/create`, {
            body: JSON.stringify(request, localDateSerialiserReplacer(["startDate", "dueDate"])),
            prefixUrl: prefixUrl,
        })
        .json();

export const generateQuestionAnswerActionItem = async (
    prefixUrl: string,
    request: GenerateQuestionAnswerActionItemRequest
): Promise<Response<number | null>> =>
    await client
        .post(`${route}/generate`, {
            body: JSON.stringify(request, localDateSerialiserReplacer(["dateTimeValueLocal"])),
            prefixUrl: prefixUrl,
        })
        .json();

export const getAdditionalFields = async (
    prefixUrl: string,
    actionItemTypeNodeId: number
): Promise<Response<ActionItemDynamicFieldDetailsResponse[]>> =>
    await client
        .get(`${route}/additional-fields/${actionItemTypeNodeId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const getAdditionalFieldValues = async (
    prefixUrl: string,
    actionItemId: number
): Promise<Response<ActionItemDynamicFieldValueResponse[]>> =>
    await client
        .get(`${route}/additional-field-values/${actionItemId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const getActionItemDetails = async (
    prefixUrl: string,
    actionItemId: number
): Promise<Response<ActionItemDetailsResponse>> =>
    await client
        .get(`${route}/${actionItemId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const cancelActionItem = async (
    prefixUrl: string,
    request: CancelActionItemRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/cancel`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const getAssociatedRaciParticipantDetails = async (
    prefixUrl: string,
    request: ActionItemRaciParticipantDetailsRequest
): Promise<Response<RaciSetUserResponse>> => {
    const searchParams = constructRaciParticipantDetailsSearchParamsFromActionItem(request);

    return await client
        .get(`${route}/associated-raci-set-user`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const filterAssociatedRaciSetUsers = async (
    prefixUrl: string,
    request: FilterAssociatedRaciSetUsersRequest
): Promise<Response<PaginationResponse<RaciSetUserResponse>>> => {
    const searchParams = buildAssociatedRaciSetUsersSearchParameters(request);

    return await client
        .get(`${route}/associated-raci-set-users/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const AssociateRaciParticipant = async (
    prefixUrl: string,
    request: AssociateRaciParticipantRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/associate-raci-participant`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const dissociateRaciParticipant = async (
    prefixUrl: string,
    request: DissociateRaciParticipantRequest
): Promise<Response<boolean>> =>
    await client
        .delete(`${route}/dissociate-raci-participant`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const editActionItem = async (
    prefixUrl: string,
    request: EditActionItemRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/edit`, {
            body: JSON.stringify(request, localDateSerialiserReplacer(["startDate", "dueDate"])),
            prefixUrl: prefixUrl,
        })
        .json();

export const placeOnHold = async (
    prefixUrl: string,
    request: PlaceOnHoldActionItemRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/place-on-hold`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const reactivateOnHold = async (
    prefixUrl: string,
    request: ReactivateOnHoldActionItemRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/reactivate-on-hold`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const completeActionItem = async (
    prefixUrl: string,
    request: CompleteActionItemRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/complete`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const extendActionItem = async (
    prefixUrl: string,
    request: ExtendActionItemRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/extend`, {
            body: JSON.stringify(request, localDateSerialiserReplacer(["dueDate"])),
            prefixUrl: prefixUrl,
        })
        .json();

export const getActionItemInstanceFiles = async (
    prefixUrl: string,
    actionItemId: number | null
): Promise<Response<ActionItemInstanceFileDownloadResponse[]>> =>
    await client
        .get(`${route}/files/${actionItemId ?? ""}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const uploadActionItemInstanceFile = async (
    prefixUrl: string,
    request: ActionItemInstanceFileUploadRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/upload-file`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const downloadActionItemInstanceFile = async (
    prefixUrl: string,
    actionItemInstanceFileId: number
): Promise<Response<ActionItemInstanceFileDownloadResponse>> =>
    await client
        .get(`${route}/download-file/${actionItemInstanceFileId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const removeActionItemInstanceFile = async (
    prefixUrl: string,
    actionItemInstanceFileId: number
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/remove-file/${actionItemInstanceFileId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const filterFinalizedActionItems = async (
    prefixUrl: string,
    request: FilterFinalizedActionItemsRequest
): Promise<Response<PaginationResponse<MyActionItemResponse>>> => {
    const searchParams = buildFilterSearchFinalizedActionItemsParameters(request);

    return await client
        .get(`${route}/my-finalised-action-items/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const reopenFinalizedActionItem = async (
    prefixUrl: string,
    request: ReopenFinalizedActionItemRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/re-open-finalised`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const getActionItemInstanceComments = async (
    prefixUrl: string,
    actionItemInstanceId: number
): Promise<Response<ActionItemInstanceCommentResponse[]>> =>
    await client
        .get(`${route}/action-item-instance-comments/${actionItemInstanceId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const createActionItemInstanceComment = async (
    prefixUrl: string,
    request: ActionItemInstanceCommentRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/create-action-item-instance-comment`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const getAssociatedReferentialLinks = async (
    prefixUrl: string,
    request: FilterActionItemAssociationRequest
): Promise<Response<PaginationResponse<ActionItemReferentialLinkResponse>>> => {
    const searchParams = buildFilterActionItemAssociationSearchParameters(request);

    return await client
        .get(`${route}/associated-referential-links`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const dissociateReferentialLink = async (
    prefixUrl: string,
    request: AssociateDissociateReferentialLinkToActionItemRequest
): Promise<Response<boolean>> =>
    await client
        .delete(`${route}/dissociate-referential-link`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const associateActionItemReferentialLink = async (
    prefixUrl: string,
    request: AssociateDissociateReferentialLinkToActionItemRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/associate-referential-link`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const getAssociatedNodes = async (
    prefixUrl: string,
    request: FilterActionItemNodeAssociationRequest
): Promise<Response<PaginationResponse<BaseNodeDetailsResponse>>> => {
    const searchParams = buildFilterActionItemNodeAssociationSearchParameters(request);

    return await client
        .get(`${route}/associated-nodes`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const associateNode = async (
    prefixUrl: string,
    request: AssociateNodeToActionItemRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/associate-node`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const dissociateNode = async (
    prefixUrl: string,
    request: DissociateNodeToActionItemRequest
): Promise<Response<boolean>> =>
    await client
        .delete(`${route}/dissociate-node`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const markCommentsAsRead = async (
    prefixUrl: string,
    actionItemInstanceId: number
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/mark-comments-as-read/${actionItemInstanceId}`, {
            prefixUrl: prefixUrl,
        })
        .json();
