import { AssociateActionItemSubTypeNodeRequest } from "../../../domain/requests/questions/associate-action-item-sub-type-node-request";
import { AssociateChildQuestionRequest } from "../../../domain/requests/questions/associate-child-question-request";
import { AssociateDissociateQuestionTaxonomyNodeRequest } from "../../../domain/requests/questions/associate-dissociate-question-taxonomy-node-request";
import { CreateAlternativeQuestionTextRequest } from "../../../domain/requests/questions/create-alternative-question-text-request";
import { CreateQuestionRequest } from "../../../domain/requests/questions/create-question-request";
import { EditActionItemSubTypeNodeRequest } from "../../../domain/requests/questions/edit-action-item-sub-type-node-request";
import { EditNestedQuestionTriggerSelectionRequest } from "../../../domain/requests/questions/edit-nested-question-trigger-selection-request";
import { EditQuestionRequest } from "../../../domain/requests/questions/edit-question-request";
import {
    buildFilterNestedQuestionsSearchParameters,
    FilterNestedQuestionsRequest,
} from "../../../domain/requests/questions/filter-nested-questions-request";
import {
    buildFilterQuestionAssociationSearchParameters,
    FilterQuestionAssociationRequest,
} from "../../../domain/requests/questions/filter-question-association-request";
import {
    buildFilterSearchParameters,
    FilterQuestionsRequest,
} from "../../../domain/requests/questions/filter-questions-request";
import { OrderNestedQuestionsRequest } from "../../../domain/requests/questions/order-nested-questions";
import { PaginationResponse } from "../../../domain/responses/common/pagination-response";
import { Response } from "../../../domain/responses/common/response-response";
import { QuestionSetResponse } from "../../../domain/responses/question-sets/question-set-response";
import { ActionItemTypeAndSubTypeResponse } from "../../../domain/responses/questions/action-item-type-and-sub-type-response";
import { AlternativeQuestionTextResponse } from "../../../domain/responses/questions/alternative-question-text-response";
import { NestedQuestionResponse } from "../../../domain/responses/questions/nested-question-response";
import { QuestionDetailsResponse } from "../../../domain/responses/questions/question-details-response";
import { QuestionResponse } from "../../../domain/responses/questions/question-response";
import { QuestionTaxonomyLinksResponse } from "../../../domain/responses/questions/question-taxonomy-links-response";
import client from "../../http-client";

const route = "questions";

export const filterQuestions = async (
    prefixUrl: string,
    request: FilterQuestionsRequest
): Promise<Response<PaginationResponse<QuestionResponse>>> => {
    const searchParams = buildFilterSearchParameters(request);

    return await client
        .get(`${route}/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const getQuestionDetails = async (
    prefixUrl: string,
    questionId: number
): Promise<Response<QuestionDetailsResponse>> =>
    await client
        .get(`${route}/${questionId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const associateTaxonomyNode = async (
    prefixUrl: string,
    request: AssociateDissociateQuestionTaxonomyNodeRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/associate-taxonomy-node`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const createQuestion = async (
    prefixUrl: string,
    request: CreateQuestionRequest
): Promise<Response<number>> =>
    await client
        .post(`${route}/create`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const softDeleteQuestion = async (
    prefixUrl: string,
    questionId: number
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/delete/${questionId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const editQuestion = async (
    prefixUrl: string,
    request: EditQuestionRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/edit`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const filterAssociatedTaxonomyNodes = async (
    prefixUrl: string,
    request: FilterQuestionAssociationRequest
): Promise<Response<PaginationResponse<QuestionTaxonomyLinksResponse>>> => {
    const searchParams = buildFilterQuestionAssociationSearchParameters(request);

    return await client
        .get(`${route}/associated-taxonomy-nodes`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const dissociateTaxonomyNode = async (
    prefixUrl: string,
    request: AssociateDissociateQuestionTaxonomyNodeRequest
): Promise<Response<boolean>> =>
    await client
        .delete(`${route}/dissociate-taxonomy-node`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const filterAlternativeQuestionTexts = async (
    prefixUrl: string,
    request: FilterQuestionAssociationRequest
): Promise<Response<PaginationResponse<AlternativeQuestionTextResponse>>> => {
    const searchParams = buildFilterQuestionAssociationSearchParameters(request);

    return await client
        .get(`${route}/alternative-question-texts`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const deleteAlternativeQuestionText = async (
    prefixUrl: string,
    questionTextId: number
): Promise<Response<boolean>> =>
    await client
        .delete(`${route}/delete-alternative-question-text/${questionTextId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const getAllAlternativeQuestionsTexts = async (
    prefixUrl: string,
    request: FilterQuestionAssociationRequest
): Promise<Response<PaginationResponse<AlternativeQuestionTextResponse>>> => {
    const searchParams = buildFilterQuestionAssociationSearchParameters(request);

    return await client
        .get(`${route}/all-alternative-question-texts`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const createAlternativeQuestionText = async (
    prefixUrl: string,
    request: CreateAlternativeQuestionTextRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/create-alternative-question-text`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const getNestedQuestionDetailsById = async (
    prefixUrl: string,
    nestedQuestionId: number
): Promise<Response<NestedQuestionResponse>> =>
    await client
        .get(`${route}/nested-question/${nestedQuestionId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const editTriggerSelectionForNestedQuestion = async (
    prefixUrl: string,
    request: EditNestedQuestionTriggerSelectionRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/edit-nested-question-trigger-selection`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const dissociateNestedQuestion = async (
    prefixUrl: string,
    nestedQuestionId: number
): Promise<Response<boolean>> =>
    await client
        .delete(`${route}/dissociate-nested-question/${nestedQuestionId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const filterNestedQuestions = async (
    prefixUrl: string,
    request: FilterNestedQuestionsRequest
): Promise<Response<PaginationResponse<NestedQuestionResponse>>> => {
    const searchParams = buildFilterNestedQuestionsSearchParameters(request);
    return await client
        .get(`${route}/nested-questions`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const filterAssociatedQuestionSets = async (
    prefixUrl: string,
    request: FilterQuestionAssociationRequest
): Promise<Response<PaginationResponse<QuestionSetResponse>>> => {
    const searchParams = buildFilterQuestionAssociationSearchParameters(request);
    return await client
        .get(`${route}/associated-question-sets`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const orderNestedQuestions = async (
    prefixUrl: string,
    request: OrderNestedQuestionsRequest
): Promise<Response<boolean>> =>
    await client
        .put(`${route}/order-nested-questions`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const associateChildQuestion = async (
    prefixUrl: string,
    request: AssociateChildQuestionRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/associate-child-question`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const associateActionItemSubTypeNode = async (
    prefixUrl: string,
    request: AssociateActionItemSubTypeNodeRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/associate-action-item-sub-type-node`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const associatedActionItemSubTypeNode = async (
    prefixUrl: string,
    questionId: number
): Promise<Response<PaginationResponse<ActionItemTypeAndSubTypeResponse>>> =>
    await client
        .get(`${route}/associated-action-item-sub-type-node?questionId=${questionId}`, {
            prefixUrl: prefixUrl,
        })
        .json();

export const editActionItemSubTypeNode = async (
    prefixUrl: string,
    request: EditActionItemSubTypeNodeRequest
): Promise<Response<boolean>> =>
    await client
        .post(`${route}/edit-action-item-sub-type-node`, {
            body: JSON.stringify(request),
            prefixUrl: prefixUrl,
        })
        .json();

export const disassociateActionItemSubTypeNode = async (
    prefixUrl: string,
    questionId: number
): Promise<Response<boolean>> =>
    await client
        .delete(`${route}/disassociate-action-item-sub-type-node/${questionId}`, {
            prefixUrl: prefixUrl,
        })
        .json();
