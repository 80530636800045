import { TableRow } from "../../../core/utilities/customTypes";
import { AnswerThresholdOperatorEnum, DataTableColumnTypes } from "../../../core/utilities/enums";
import QuestionImportance from "../../enums/questions/question-importance";
import { AnswerThresholdValueResponse } from "../../responses/answer-threshold-value/answer-threshold-value-response";
import { Response } from "../../responses/common/response-response";
import { DefaultActionItemSubTypeNodeResponse } from "../../responses/questions/default-action-item-sub-type-node-response";
import { QuestionDetailsResponse } from "../../responses/questions/question-details-response";
import { QuestionWeightingResponse } from "../../responses/questions/question-weighting-response";
import AnswerTypeDto from "../answer-types/answer-type-dto";
import { QuestionCategoryDto } from "../question-categories/question-category-dto";
import { QuestionTypeDto } from "../question-type/question-type-dto";
import { AggregationTypeDto } from "./aggregation-type-dto";
import { DefaultActionItemSubTypeNodeDto } from "./default-action-item-sub-type-node-dto";
import { QuestionTextDto } from "./question-text-dto";

export interface QuestionDetailsDto {
    questionId: number;
    questionTypeId: number;
    answerTypeId: number;
    questionCategoryId: number;
    questionImportance: QuestionImportance;
    isDeleted: boolean;
    dateCreated: Date;
    dateModified: Date;
    createdByUserFullName: string;
    createdByUserId: number | null;
    modifiedByUserFullName: string;
    modifiedByUserId: number | null;
    isAggregate: boolean;
    aggregationTypeId: number;
    isARMonitoring: boolean | null;
    questionTextDto: QuestionTextDto;
    questionType: QuestionTypeDto;
    questionCategory: QuestionCategoryDto;
    answerTypeDto: AnswerTypeDto;
    aggregationType: AggregationTypeDto;
    defaultActionItemSubTypeNodeDto: DefaultActionItemSubTypeNodeResponse | null;
    answerThresholdValueResponses: AnswerThresholdValueResponse[];
    answerThresholdValueResponseRows: TableRow<number | null>[];
    questionWeightings: QuestionWeightingResponse[];
}

export const createQuestionDetailsDto = (
    response: Response<QuestionDetailsResponse>
): QuestionDetailsDto => {
    const responseData = response.data;

    return {
        questionId: responseData.questionId,
        questionTypeId: responseData.questionTypeId,
        answerTypeId: responseData.answerTypeId,
        questionCategoryId: responseData.questionCategoryId,
        questionImportance: responseData.questionImportance,
        isDeleted: responseData.isDeleted,
        dateCreated: responseData.dateCreated,
        dateModified: responseData.dateModified,
        createdByUserFullName: responseData.createdByUserFullName,
        createdByUserId: responseData.createdByUserId,
        modifiedByUserFullName: responseData.modifiedByUserFullName,
        modifiedByUserId: responseData.modifiedByUserId,
        isAggregate: responseData.isAggregate,
        aggregationTypeId: responseData.aggregationTypeId,
        isARMonitoring: responseData.isARMonitoring,
        questionTextDto: responseData.questionText,
        questionType: responseData.questionType,
        questionCategory: responseData.questionCategory,
        answerTypeDto: new AnswerTypeDto(responseData.answerTypeResponse),
        defaultActionItemSubTypeNodeDto: responseData.defaultActionItemSubTypeNodeResponse
            ? new DefaultActionItemSubTypeNodeDto(
                  responseData.defaultActionItemSubTypeNodeResponse!
              )
            : null,
        aggregationType: responseData.aggregationType,
        answerThresholdValueResponses: responseData.answerThresholdValueResponses,
        answerThresholdValueResponseRows: transformToDataTableRows(
            responseData.answerThresholdValueResponses
        ),
        questionWeightings: responseData.questionWeightingResponses,
    };
};

const transformToDataTableRows = (
    response: AnswerThresholdValueResponse[]
): TableRow<number | null>[] => {
    const rows = response.map((answerThresholdValueResponse) => ({
        metadata: answerThresholdValueResponse.answerThresholdValueId,
        columns: [
            {
                value: answerThresholdValueResponse.answerThresholdIndicatorName,
                type: DataTableColumnTypes.Text,
            },
            {
                value: AnswerThresholdOperatorEnum[
                    answerThresholdValueResponse.answerThresholdOperatorId
                ],
                type: DataTableColumnTypes.Text,
            },
            {
                value: answerThresholdValue(answerThresholdValueResponse)?.toString(),
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return rows;
};

const answerThresholdValue = (answerThresholdValueResponse: AnswerThresholdValueResponse): any => {
    if (answerThresholdValueResponse.dateValue) {
        return answerThresholdValueResponse.dateValue;
    }
    if (answerThresholdValueResponse.intValue) {
        return answerThresholdValueResponse.intValue;
    }
    if (answerThresholdValueResponse.decimalValue) {
        return answerThresholdValueResponse.decimalValue;
    }
};
